<template>
    <div>
        <div v-if="displayCalendar">
            <FullCalendar
            class="h-[90vh]"
            ref="calendarDay"
            :options="calendarOptions"
            />
        </div>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'; // Pour Vue 2
import frLocale from '@fullcalendar/core/locales/fr';
import timeGridPlugin from '@fullcalendar/timegrid'; // Import du plugin
import listPlugin from '@fullcalendar/list'; // Import du plugin

export default {
    components: {
        FullCalendar
    },
    props: {
        confPlanning: {},
        selectedData: {},
        events: []
    },
    watch: {
        selectedData: {
            deep: true,
            handler() {
                this.setDate()
            }
        }
    },
    data() {
        return {
            displayCalendar: false,
            calendarOptions: {
                plugins: [timeGridPlugin, listPlugin],
                locales: [ frLocale ],
                locale: 'fr',
                timeZone: 'Europe/Paris',
                headerToolbar: false,
                initialView: 'timeGridDay',
                events: [],
                allDaySlot: false,
                views: {
                    timeGridDay: {
                        slotDuration: '00:15:00', // Intervalles de 15 minutes
                        slotLabelInterval: '00:15:00',
                        slotMinTime: '08:00:00',
                        slotMaxTime: '18:00:00',
                    },
                },
                slotEventOverlap: false,
                schedulerLicenseKey: '0064802622-fcs-1734624337',
                slotLabelFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: false
                },
            }
        }
    },
    methods: {
        init() {
            if (this.confPlanning && this.calendarOptions.initialView == 'timeGridDay') {
                this.calendarOptions.views.timeGridDay.slotDuration = this.confPlanning.interval
                this.calendarOptions.views.timeGridDay.slotLabelInterval = this.confPlanning.interval
                this.calendarOptions.views.timeGridDay.slotMinTime = this.confPlanning.min_time
                this.calendarOptions.views.timeGridDay.slotMaxTime = this.confPlanning.max_time
            }
            this.displayCalendar = true

            this.$nextTick(() => {
                this.highlightHours();

                this.setDate()
            })

            this.calendarOptions.events = this.events
        },

        setDate() {
            if (this.$refs.calendarDay) {
                const calendarApi = this.$refs.calendarDay.getApi();
                calendarApi.gotoDate(this.selectedData.date);
            }
        },

        highlightHours() {
            // const labels = this.$refs.calendarDay.$el.querySelectorAll('.fc-timegrid-slot-label-cushion');

            // labels.forEach((label) => {
            //     const text = label.textContent || label.innerText;
            //     console.log(text);
                

            //     // Vérifie si l'heure est une heure principale (se termine par ":00")
            //     if (text.endsWith(':00')) {
            //         label.classList.add('hour-label'); // Ajoute une classe personnalisée
            //         label.innerHTML = text.split(':')[0] + 'h'
            //     } else {
            //         label.classList.add('minute-label'); // Ajoute une classe pour les minutes
            //         label.innerHTML = text.split(':')[1]
            //     }
            // });
        },
    },
    mounted() {
        this.init()
    }
}
</script>

<style>
.fc-col-header-cell-cushion {
    font-size: 14px;
}

/* Styliser les heures */
.hour-label {
    font-size: 0.9em !important;
    font-weight: bold;
}

/* Styliser les minutes */
.minute-label {
    font-size: 0.7em !important;
    font-weight: normal;
    color: #888 !important; /* Texte plus clair pour les minutes */
}

.fc-cell-shaded {
    display: none; /* Cache le nom du jour */
}
</style>