import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// Default Views
import Home from '../views/Home.vue'
import Auth from '../views/Auth.vue'
import Logout from '../views/Logout.vue'
import Login from '../views/Login.vue'
import PageNotFound from '../views/PageNotFound.vue'
import LoginA2F from '../views/LoginA2F.vue'
import A2FValidMail from '../views/A2FValidMail.vue'

// Ecosystem
import EcoSystemFoldersList from '../views/EcoSystem/EcoSystemFoldersList.vue'
import EcoSystemCategories from '../views/EcoSystem/EcoSystemCategories.vue'
import EcoSystemFolder from '../views/EcoSystem/EcoSystemFolder.vue'
import EcoSystemConnect from '../views/EcoSystem/EcoSystemConnect.vue'
import EcoSystemPartners from '../views/EcoSystem/EcoSystemPartners.vue'
import EcosystemCatalog from '../views/EcoSystem/EcoSystemCatalog.vue'
import EcoSystemPaiementHistory from '../views/EcoSystem/EcoSystemPaiementHistory.vue'
import EcosystemSendFolder from  '../views/EcoSystem/EcosystemSendFolder.vue'

// Distriboard
import DistriboardMakeAvoir from '../views/Distriboard/DistriboardMakeAvoir.vue'
import DistriboardListAvoir from '../views/Distriboard/DistriboardListAvoir.vue'

// Appareils
import AppareilList from '../views/Appareils/AppareilList'
import AppareilForm from '../views/Appareils/AppareilForm'

// Categories
import Categories from '../views/Categories/Categories'

// Procédures
import Procedure from '../views/Procedure/Procedure'

// Transporteur
import TransporteurEdit from '../views/Transporteur/TransporteurEdit'

// Selfcare edit
import SelfcareEdit from '../views/Selfcare/SelfcareEdit'

// Bdc
import BdcList from '../views/Bdc/BdcList'
import BdcEditor from '../views/Bdc/BdcEditor'
import BdcTchat from '../views/Bdc/BdcTchat'

// Conversions
import ShoppingFeed from '../views/Conversions/ShoppingFeed'

// Vitrine
import VitrineArticles from '../views/Vitrine/VitrineArticles'
import VitrinePricing from '../views/Vitrine/VitrinePricing'
import VitrineSimulations from '../views/Vitrine/VitrineSimulations'

// Permissions
import PermissionsList from '../views/Admin/Permissions/PermissionsList'
import PermissionUsers from '../views/Admin/Permissions/PermissionUsers.vue'

// Inscription
import InscriptionForm from '../views/Admin/Inscription/InscriptionForm'
import InscriptionForgetPassword from '../views/Admin/Inscription/InscriptionForgetPassword'
import InscriptionClaimView from '../views/Admin/Inscription/InscriptionClaimView'
import InscriptionComplement from '../views/Admin/Inscription/InscriptionComplement'

// Client
import ClientList from '../views/Admin/Client/ClientList'
import ClientView from '../views/Admin/Client/ClientView'

// Magasins
import MagasinList from '../views/Admin/Magasin/MagasinList'
import MagasinInviteReparateur from '../views/Admin/Magasin/MagasinInviteReparateur'
import MagasinStation from '../views/Admin/Magasin/MagasinStation'

// Facturation
import FacturationClientList from '../views/Admin/Facturation/FacturationClientList'
import FacturationEditClientOptions from '../views/Admin/Facturation/FacturationEditClientOptions'
import FacturationManageOptions from '../views/Admin/Facturation/FacturationManageOptions'
import FacturationWaitingConnexion from '../views/Admin/Facturation/FacturationWaitingConnexion'

// Emails
import EmailList from '../views/Admin/Email/EmailList'

// Liaisons
import AdminManageLiaison from '../views/Admin/Liaisons/AdminManageLiaison'

// Admin client
import AdminClientLiaisons from '../views/AdminClient/AdminClientLiaisons'
import AdminClientEntite from '../views/AdminClient/AdminClientEntite'
import AdminClientUsers from '../views/AdminClient/AdminClientUsers'
import AdminClientFacturation from '../views/AdminClient/AdminClientFacturation'
import AdminClientPricingRecap from '../views/AdminClient/AdminClientPricingRecap'

// Welcome
import WelcomeSettings from '../views/AdminClient/Welcome/WelcomeSettings'

// Errors
import ErrorList from '../views/Admin/Error/ErrorList'

// EcosystemAdmin
import EcosystemAdminList from '../views/Admin/Ecosystem/EcosystemAdminList'

// Monitoring
import AdminMonitoring from '../views/Admin/Monitoring/AdminMonitoring'

// Server log
import ServerLogs from '../views/Admin/ServerLogs/ServerLogs'

// Upload File
import UploadFile from '../views/Admin/Upload/UploadFile'

// Invitations
import AdminInvitations from '../views/Admin/Invitation/AdminInvitations'

// Suivi dossier
import SuiviDossier from '../views/Suivi/SuiviDossier'

// widget config
import widgets from './widgets.js'

// Reparateur
import ReparateurConf from '../views/Reparateur/ReparateurConf'
import ValidationAgrement from '../views/Reparateur/ValidationAgrement'
import ReparateurFacturation from '../views/Reparateur/ReparateurFacturation'
import ReparateurStock from '../views/Reparateur/ReparateurStock'
import ReparateurCommandeStock from '../views/Reparateur/ReparateurCommandeStock'
import ReparateurDemandeComplement from '../views/Reparateur/ReparateurDemandeComplement'
import ReparateurTechniciens from '../views/Reparateur/ReparateurTechniciens'
import TechniciensFonctions from '../views/Reparateur/TechniciensFonctions'
import TechnicienPlanning from '../views/Reparateur/TechnicienPlanning'
import TechnicienPlanning2 from '../views/Reparateur/TechnicienPlanning2'
import TestSocket from '../views/Reparateur/TestSocket.vue'

var middlewareUserAuth = (to, from, next) => {
    if (!localStorage['auth'] && !localStorage['account']) {
        next({name: 'login'})
    }
    next()
}

const routes = [
    {path: '/login', name: 'login', component: Login},
    {path: '/a2f', name: 'a2f', component: LoginA2F},
    {path: '/a2f-valid-email', name: 'a2f-valid-email', component: A2FValidMail},
    {path: '/logout', name: 'logout', component: Logout},
    {path: '/auth', name: 'auth', component: Auth},
    {path: '/auth2', name: 'auth', component: Auth},
    {path: '/', name: 'home', component: Home, beforeEnter: middlewareUserAuth},

    // Ecosystem
    {path: '/ecosysteme/dossiers', name: 'ecosystem.folders', component: EcoSystemFoldersList, beforeEnter: middlewareUserAuth, meta: {permissions: ['ecosystem']}},
    {path: '/ecosysteme/dossier/:ref', name: 'ecosystem.folder.view', component: EcoSystemFolder, beforeEnter: middlewareUserAuth, meta: {permissions: ['ecosystem']}},
    {path: '/ecosysteme/categories', name: 'ecosystem.categories', component: EcoSystemCategories, beforeEnter: middlewareUserAuth, meta: {permissions: ['ecosystem']}},
    {path: '/ecosysteme/partners', name: 'ecosystem.partners', component: EcoSystemPartners, beforeEnter: middlewareUserAuth, meta: {permissions: ['add_ecosystem_partner']}},
    {path: '/ecosysteme/connexion', name: 'ecosystem.connect', component: EcoSystemConnect, beforeEnter: middlewareUserAuth, meta: {permissions: ['ecosystem']}},
    {path: '/ecosysteme/catalog', name: 'ecosystem.catalog', component: EcosystemCatalog, beforeEnter: middlewareUserAuth, meta: {permissions: ['ecosystem']}},
    {path: '/ecosysteme/paiements', name: 'ecosystem.paiement-history', component: EcoSystemPaiementHistory, beforeEnter: middlewareUserAuth, meta: {permissions: ['ecosystem']}},
    {path: '/ecosysteme/envoi-dossier', name: 'ecosystem.send-folder', component: EcosystemSendFolder, beforeEnter: middlewareUserAuth, meta: {permissions: ['ecosystem']}},

    // Distriboard
    {path: '/distriboard/creer-un-avoir', name: 'distriboard.make-avoir', component: DistriboardMakeAvoir, beforeEnter: middlewareUserAuth, meta: {permissions: ['distriboard']}},
    {path: '/distriboard/liste-des-avoirs', name: 'distriboard.list-avoir', component: DistriboardListAvoir, beforeEnter: middlewareUserAuth, meta: {permissions: ['distriboard']}},

    // Appareils
    {path: '/appareils/list', name: 'apps.list', component: AppareilList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin_client']}},
    {path: '/appareils/form', name: 'apps.form', component: AppareilForm, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin_client']}},

    // Categories
    {path: '/gestion-categories', name: 'manage-categories', component: Categories, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin_client']}},

    // Procedures
    {path: '/procedures', name: 'procedure', component: Procedure, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin_client']}},

    // Transporteurs
    {path: '/transporteurs/edit', name: 'transporteur.edit', component: TransporteurEdit, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin_client']}},

    // Manage selfcare
    {path: '/selfcare/edit', name: 'selfcare.edit', component: SelfcareEdit, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin_client']}},

    // Conversion
    {path: '/conversion/shopping-feed', name: 'conversion.shopping-feed', component: ShoppingFeed, beforeEnter: middlewareUserAuth, meta: {permissions: ['conversion_shoppingfeed']}},

    // bdc
    {path: '/bdc/editeur', name: 'bdc.editor', component: BdcEditor, beforeEnter: middlewareUserAuth, meta: {permissions: ['bdc']}},
    {path: '/bdc/list', name: 'bdc.list', component: BdcList, beforeEnter: middlewareUserAuth, meta: {permissions: ['bdc']}},
    {path: '/bdc/tchat', name: 'bdc.tchat', component: BdcTchat},

    // Vitrine
    {path: '/vitrine/articles', name: 'vitrine.articles', component: VitrineArticles, beforeEnter: middlewareUserAuth, meta: {permissions: ['site_vitrine']}},
    {path: '/vitrine/pricing', name: 'vitrine.pricing', component: VitrinePricing, beforeEnter: middlewareUserAuth, meta: {permissions: ['site_vitrine']}},
    {path: '/vitrine/simulations', name: 'vitrine.simulations', component: VitrineSimulations, beforeEnter: middlewareUserAuth, meta: {permissions: ['site_vitrine']}},


    /* ===============
        ADMIN
    =============== */

    // Inscription
    {path: '/inscription/demande-complement', name: 'inscription.demande-complement', component: InscriptionComplement, meta: {domains: ['auth.districloud.net']}},
    {path: '/inscription', name: 'inscription.form', component: InscriptionForm, meta: {domains: ['auth.districloud.net']}},
    {path: '/inscription/demande', name: 'inscription.claim', component: InscriptionClaimView, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/mot-de-passe-oublie', name: 'inscription.forget-password', component: InscriptionForgetPassword, meta: {domains: ['auth.districloud.net']}},

    // Gestion des droits
    {path: '/permissions/list', name: 'permissions.list', component: PermissionsList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/permissions/users', name: 'permissions.users', component: PermissionUsers, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Email
    {path: '/email/list', name: 'email.list', component: EmailList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Liaisons
    {path: '/admin/liaisons', name: 'liaisons.manage', component: AdminManageLiaison, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Error
    {path: '/errors/list', name: 'errors.list', component: ErrorList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Ecosystem admin
    {path: '/admin/ecosystem/list', name: 'ecosystem.list', component: EcosystemAdminList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Magasins
    {path: '/magasin/invite-reparateur', name: 'magasin.invite-reparateur', component: MagasinInviteReparateur, beforeEnter: middlewareUserAuth},
    {path: '/magasin/station-reparation', name: 'magasin.station-reparation', component: MagasinStation, beforeEnter: middlewareUserAuth},
    {path: '/magasins/list', name: 'magasin.list', component: MagasinList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Facturation
    {path: '/facturation/list', name: 'facturation.client-list', component: FacturationClientList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/facturation/edit-client-options', name: 'facturation.edit-client-options', component: FacturationEditClientOptions, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/facturation/gestion-options', name: 'facturation.manage-options', component: FacturationManageOptions, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/facturation/waiting-connexion', name: 'facturation.waiting-connexion', component: FacturationWaitingConnexion, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Clients
    {path: '/clients', name: 'client.list', component: ClientList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/client/:id', name: 'client.view', component: ClientView, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Admin client
    {path: '/client/admin/liaisons', name: 'client.admin.liaisons', component: AdminClientLiaisons, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin_client']}},
    {path: '/client/admin/entite', name: 'client.admin.entite', component: AdminClientEntite, beforeEnter: middlewareUserAuth},
    {path: '/client/admin/utilisateurs', name: 'client.admin.users', component: AdminClientUsers, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin_client']}},
    {path: '/client/admin/facturation', name: 'client.admin.facturation', component: AdminClientFacturation, beforeEnter: middlewareUserAuth},
    {path: '/client/admin/prix', name: 'client.admin.pricing-recap', component: AdminClientPricingRecap, beforeEnter: middlewareUserAuth},

    // welcome settings
    {path: '/welcome/settings', name: 'welcome.settings', component: WelcomeSettings, beforeEnter: middlewareUserAuth},

    {path: '/monitoring', name: 'admin.monitoring', component: AdminMonitoring, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/invitations', name: 'admin.invitations', component: AdminInvitations, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/serveur/logs', name: 'admin.logs', component: ServerLogs, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},
    {path: '/upload-file', name: 'admin.upload-file', component: UploadFile, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},


    // Centrale
    // {path: '/centrale/list', name: 'centrale.list', component: CentraleList, beforeEnter: middlewareUserAuth, meta: {permissions: ['admin']}},

    // Suivi
    {path: '/suivi/dossier/:ref', name: 'suivi.dossier', component: SuiviDossier},

    // Reparateur
    {path: '/reparateur/configuration', name: 'reparateur.conf', component: ReparateurConf},
    {path: '/reparateur/validation-agrements/:token', name: 'reparateur.validation-agrements', component: ValidationAgrement},
    {path: '/reparateur/facturation', name: 'reparateur.facturation', component: ReparateurFacturation},
    {path: '/reparateur/stock', name: 'reparateur.stock', component: ReparateurStock},
    {path: '/reparateur/commande-stock', name: 'reparateur.commande-stock', component: ReparateurCommandeStock},
    {path: '/reparateur/techniciens', name: 'reparateur.techniciens', component: ReparateurTechniciens},
    {path: '/reparateur/techniciens/fonctions', name: 'reparateur.techniciens-fonctions', component: TechniciensFonctions},
    {path: '/reparateur/techniciens/planning', name: 'reparateur.techniciens-planning', component: TechnicienPlanning},
    {path: '/reparateur/techniciens/planning2', name: 'reparateur.techniciens-planning2', component: TechnicienPlanning2},
    {path: '/reparateur/test-socket', name: 'reparateur.test-socket', component: TestSocket},
    {path: '/reparation/complement/:dossierToken', name: 'reparateur.demande-complement', component: ReparateurDemandeComplement},


    // 404
    { path: "*", component: PageNotFound, name: '404' }
]

// Init widgets
let currentRoute = null
let currentRouteComponent = null
let overwritedRoute = null
widgets.forEach((widgetName) => {
    // Get rouute
    overwritedRoute = routes.find((route) => route.name == widgetName)
    // Save component
    currentRouteComponent = overwritedRoute.component
    // Clone object
    currentRoute = JSON.parse(JSON.stringify(overwritedRoute))
    // Set default component in cloned object
    currentRoute.component = currentRouteComponent
    // Overwrite with widget config
    if (currentRoute) {
        currentRoute.path = '/widget' + currentRoute.path
        currentRoute.name = 'widget.' + currentRoute.name
        if (!currentRoute.meta) {
            currentRoute.meta = {}
        }
        currentRoute.meta.widget = true
        routes.push(currentRoute)
    }
})

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach(async (to, from, next) => {
    let canAccess = true
    let permissions = JSON.parse(localStorage.getItem('permissions'))

    // Check domain name authorization
    if (to.meta && to.meta.domains) {
        if (!to.meta.domains.find((domain) => domain == window.location.hostname) && window.location.hostname != 'localhost') {
            console.log('unauthorize');
            // next('/unauthorize')
        }
    } else {
        if (window.location.hostname != 'distriadmin.savlog.net' && window.location.hostname != 'distriadmin-preprod.savlog.net' && window.location.hostname != 'localhost') {
            console.log('unauthorize');
            // next('/unauthorize')
        }
    }

    // Check permission
    if (to.meta.permissions && permissions) {
        canAccess = to.meta.permissions.filter(value => permissions.includes(value)).length > 0 ? true : false
    }

    // Middleware
    if (!canAccess && to.name != 'home') {
        next({ name: 'home' })
    } else {
        next()
    }
})

export default router
