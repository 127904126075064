<template>
    <div>
        <app-template>
            <div class="bg-white p-7 rounded border shadow-sm mb-16">
                <div class="p-4" v-if="loading">
                    <spinner />
                </div>
                <div v-if="!loading">
                    <div>
                        <div class="bg-white rounded-lg border w-[500px] h-[300px]">
                            <!-- Header -->
                            <div class="border-b rounded-t p-2 flex items-center justify-between" :style="'background-color: ' + form.mainColor">
                                <div class="flex items-center gap-2">
                                    <div class="flex items-center gap-1">
                                        <div class="flex-col items-center justify-between">
                                            <div v-if="!form.logo" class="px-2 py-0.5 bg-red-500 ml-4 text-xs text-white">Logo</div>
                                            <img v-if="form.logo" class="h-[25px] ml-[15px]" :src="form.logo" />
                                        </div>
                                        <div v-if="!form.headerHideBrand" :style="'color: ' + form.headerTextSelectedColor">
                                            <div class="text-xs font-semibold">{{ form.name }}</div>
                                            <div v-if="form.slogan" class="-mt-1 font-light" style="font-size: 9px">{{ form.slogan }}</div>
                                        </div>
                                    </div>
                                    <div class="flex items-center gap-2 border-l pl-2" style="font-size: 10px">
                                        <div :style="'color: ' + form.headerTextSelectedColor">Accueil</div>
                                        <div :style="'color: ' + form.headerTextColor">Mes demandes</div>
                                    </div>
                                </div>
                                <img class="h-[20px] rounded-full" src="https://avatars.dicebear.com/api/initials/victor.svg?background=%23bdbdbd" alt="">
                            </div>
                            <div class="flex p-5 gap-4">
                                <div class="rounded bg-red-500 w-[150px] h-[180px] text-white font-light flex items-center justify-center text-sm" v-if="!form.images.default">
                                    Image principale
                                </div>
                                <div class="w-[150px] h-[180px] rounded" v-if="form.images.default" :src="form.images.default" style="background-size: cover; background-position: center center;" :style="'background-image: url(' + form.images.default + ')'"></div>
                                <div class="font-bold text-slate-400 border rounded w-[350px] flex items-center justify-center">
                                    Contenu du site
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-6 mt-4 gap-4">
                        <div class="col-span-2">
                            <div class="border px-4 rounded sticky top-5">
                                <div @click="selectedTab = 'custom'" class="py-4 border-b cursor-pointer" :class="selectedTab == 'custom' ? 'font-medium text-blue-500' : 'opacity-60 hover:font-medium hover:opacity-100 duration-200 hover:font-medium'">
                                    <i class="fas fa-palette mr-1"></i> Personnalisation
                                </div>
                                <div @click="selectedTab = 'images'" class="py-4 border-b cursor-pointer"  :class="selectedTab == 'images' ? 'font-medium text-blue-500' : 'opacity-60 hover:font-medium hover:opacity-100 duration-200 hover:font-medium'">
                                    <i class="fas fa-image mr-1"></i> Images
                                </div>
                                <div @click="selectedTab = 'textes'" class="py-4 border-b cursor-pointer"  :class="selectedTab == 'textes' ? 'font-medium text-blue-500' : 'opacity-60 hover:font-medium hover:opacity-100 duration-200 hover:font-medium'">
                                    <i class="fas fa-font mr-1"></i> Textes
                                </div>
                                <div @click="selectedTab = 'configuration'" class="py-4 cursor-pointer" :class="selectedTab == 'configuration' ? 'font-medium text-blue-500' : 'opacity-60 hover:font-medium hover:opacity-100 duration-200 hover:font-medium'">
                                    <i class="fas fa-cog mr-1"></i> Configurations
                                </div>
                            </div>
                        </div>
                        <div class="col-span-4">
                            <template v-if="selectedTab == 'custom'">
                                <div class="bg-slate-50 border p-4 rounded">
                                    <div class="font-bold border-b-2 mb-2 pb-2">Nom de l'entreprise</div>
                                    <div class="grid grid-cols-2 gap-4">
                                        <input-rule v-model="form.headerHideBrand" type="pcheck" label="Cacher le nom de l'entreprise" :label-inline="false" />
                                        <div v-if="!form.headerHideBrand">
                                            <input-rule v-model="form.slogan" type="text" label="Slogan" :label-inline="false" />
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-slate-50 border p-4 rounded mt-4">
                                    <div class="font-bold border-b-2 mb-2 pb-2">Logo</div>
                                    <div class="grid grid-cols-2 gap-4">
                                        <input-rule @input="(data) => {if (data) {form.logo = data.url}}" :options="{defaultData: {url: form.logo}}" type="file" :label-inline="false" />
                                    </div>
                                </div>
                                <div class="bg-slate-50 border p-4 rounded mt-4">
                                    <div class="font-bold border-b-2 mb-2 pb-2">Couleurs</div>
                                    <div class="grid grid-cols-2 gap-4">
                                        <input-rule v-model="form.mainColor" type="color" label="Couleur principale" :label-inline="false" />
                                        <input-rule v-model="form.headerTextColor" type="color" label="Texte menu" :label-inline="false" />
                                        <input-rule v-model="form.hoverHeaderTextColor" type="color" label="Texte menu survole" :label-inline="false" />
                                        <input-rule v-model="form.headerTextSelectedColor" type="color" label="Texte menu sélectionné" :label-inline="false" />
                                    </div>
                                </div>
                                <button :disabled="loadingEdit" @click="save" class="mt-4 px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm">
                                    <spinner v-if="loadingEdit" :size="10" />
                                    <template v-if="!loadingEdit">
                                        <i class="fas fa-check mr-1"></i> Enregistrer
                                    </template>
                                </button>
                            </template>
                            <template v-if="selectedTab == 'images'">
                                <div class="bg-slate-50 border p-4 rounded">
                                    <div class="font-bold border-b-2 mb-2 pb-2">Image principale</div>
                                    <div class="py-2 border-slate-300">
                                        <input-rule @input="(data) => {if (data) {form.images.default = data.url}}" :options="{defaultData: {url: form.images.default}}" type="file" :label-inline="false" />
                                    </div>
                                </div>
                                <div class="bg-slate-50 border p-4 rounded mt-4">
                                    <div class="font-bold border-b-2 mb-2 pb-2">Images spécifique par page</div>
                                    <div class="pt-2 border-slate-300">
                                        <div v-for="(exception, exceptionIndex) in form.images.exceptions" :key="'img-exception-' + exceptionIndex" class="flex border-b-2 border-slate-300 gap-3 pb-4 mb-4">
                                            <input-rule v-model="exception.route" type="select" :options="{values: [
                                                {label: 'Bienvenue', name: 'welcome'},
                                                {label: 'Formulaire', name: 'form.home'},
                                                {label: 'Accueil', name: 'home'},
                                                {label: 'Mes demandes', name: 'user.product.list'},
                                                {label: 'Modifier profil', name: 'user.update'},
                                                {label: 'Connexion', name: 'user.login'},
                                                {label: 'Inscription', name: 'user.register'},
                                                {label: 'Mot de passe oublié', name: 'user.password-forgot'},
                                            ], label: 'label', key: 'name'}" label="Page" :label-inline="false" />
                                            <input-rule v-model="exception.type" class="grow" type="select" :options="{values: [
                                                {label: 'Aucune image', name: 'nothing'},
                                                {label: 'Autre image', name: 'image'},
                                            ], label: 'label', key: 'name'}" label="Type d'exception" :label-inline="false" />
                                            <input-rule v-if="exception.type == 'image'" @input="(data) => {if (data) {exception.url = data.url}}" :options="{defaultData: {url: exception.url}}" class="grow" type="file" label="Nouvelle image" :label-inline="false" />
                                            <button @click="removeImgException(exceptionIndex)" class="text-red-500 text-lg ml-2 mt-5"><i class="fas fa-times"></i></button>
                                        </div>
                                        <button @click="addImgException" class="px-4 py-2 bg-slate-500 hover:bg-slate-600 font-light text-white duration-200 rounded text-xs"><i class="fas fa-plus mr-1"></i> Nouvelle règle</button>
                                    </div>
                                </div>
                                <button :disabled="loadingEdit" @click="save" class="mt-4 px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm">
                                    <spinner v-if="loadingEdit" :size="10" />
                                    <template v-if="!loadingEdit">
                                        <i class="fas fa-check mr-1"></i> Enregistrer
                                    </template>
                                </button>
                            </template>

                            <template v-if="selectedTab == 'textes'">
                                <div class="bg-slate-50 border p-4 rounded">
                                    <div class="font-bold border-b-2 mb-2 pb-2">Textes</div>
                                    <selfcare-edit-text :data="alias" />
                                </div>

                                <!-- <div class="bg-slate-50 border p-4 rounded">
                                    <div class="font-bold border-b-2 mb-2 pb-2">Textes</div>
                                    <div class="flex items-center gap-2 mb-2">
                                        <button @click="selectedLang = lang" v-for="(lang, langIndex) in textLangs" :key="'text-lang-' + langIndex" class="px-4 py-2 rounded text-sm flex items-center gap-1" :class="selectedLang.id == lang.id ? 'bg-slate-400 text-white' : 'bg-slate-200 hover:bg-slate-300 duration-200'">
                                            <img class="h-[10px]" :src="lang.logo" /> {{ lang.label }}
                                        </button>
                                    </div>
                                    <div class="grid grid-cols-4 gap-2">
                                        <div>
                                            <div class="sticky top-4">
                                                <div @click="langMenu.selected = menuItem" v-for="(menuItem, menuItemIndex) in langMenu.items" :key="'lang-menu-item-' + menuItemIndex" class="border-b p-2 cursor-pointer" :class="langMenu && langMenu.selected && langMenu.selected.key == menuItem.key ? 'bg-slate-200' : 'hover:bg-slate-200 duration-200'">
                                                    {{ menuItem.label }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-span-3">
                                            <div v-for="(text, textKey) in availableTexts" :key="'text-inp-' + textKey">
                                                <input-rule type="text" @input="changeTextException(textKey)" v-model="availableTexts[textKey]" :label-inline="false" class="mb-2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button :disabled="loadingEdit" @click="save" class="mt-4 px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm">
                                    <spinner v-if="loadingEdit" :size="10" />
                                    <template v-if="!loadingEdit">
                                        <i class="fas fa-check mr-1"></i> Enregistrer
                                    </template>
                                </button> -->
                            </template>

                            <template v-if="selectedTab == 'configuration'">
                                <div class="bg-slate-50 border p-4 rounded">
                                    <div class="font-bold border-b-2 mb-2 pb-2">Emails - Configuration SMTP</div>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <input-rule v-model="form.host" type="text" label="Host" :label-inline="false" />
                                        <input-rule v-model="form.port" type="text" label="Port" :label-inline="false" />
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <input-rule v-model="form.login" type="text" label="Login" :label-inline="false" />
                                        <input-rule v-model="form.password" type="text" label="Mot de passe" :label-inline="false" />
                                    </div>
                                    <div class="grid grid-cols-2 gap-4 mb-4">
                                        <input-rule v-model="form.secure" type="text" label="Secure" :label-inline="false" />
                                        <input-rule v-model="form.from" type="text" label="From" :label-inline="false" />
                                    </div>
                                </div>
                                <button :disabled="loadingEdit" @click="save" class="mt-4 px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm">
                                    <spinner v-if="loadingEdit" :size="10" />
                                    <template v-if="!loadingEdit">
                                        <i class="fas fa-check mr-1"></i> Enregistrer
                                    </template>
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import SelfcareEditText from './Components/SelfcareEditText.vue';

export default {
    components: {
        SelfcareEditText
    },

    data() {
        return {
            loading: false,
            loadingEdit: false,
            alias: null,
            form: {
                name: null,
                logo: null,
                slogan: null,
                mainColor: null,
                headerTextColor: null,
                headerTextSelectedColor: null,
                hoverHeaderTextColor: null,
                headerHideBrand: false,
                images: {
                    default: null,
                    exceptions: [],
                },
                host: null,
                port: null,
                login: null,
                password: null,
                secure: null,
                from: null,
                url: null
            },
            selectedTab: 'custom',
            textLangs: [],
            selectedLang: null,
            langMenu: {
                selected: null,
                items: [
                    {label: "Accueil", key: "accueil"},
                    {label: "Bienvenue", key: "bienvenue"},
                    {label: "Formulaire", key: "form"},
                    {label: "Inscription", key: "inscription"},
                    {label: "Mes produits", key: "mes_produits"},
                    {label: "Paramètres", key: "parametres"},
                    {label: "Template", key: "template"},
                ]
            }
        }
    },

    computed: {
        availableTexts() {
            if (this.langMenu && this.langMenu.selected) {
                let endObj = {}
                for (let key in this.selectedLang.contenu) {
                    if (key.split('.')[0] == this.langMenu.selected.key) {
                        endObj[key] = this.selectedLang.contenu[key]
                    }
                }
                return endObj
            }
            return []
        }
    },

    methods: {
        addImgException() {
            this.form.images.exceptions.push({page: null, type: null, url: null})
        },

        removeImgException(index) {
            this.form.images.exceptions.splice(index, 1)
        },

        async getAlias() {
            // Display loader
            this.loading = true

            // Get alias
            let response = await this.$request.post('selfcare.get-alias')
            this.alias = response.data.posts.post
            if (this.alias) {
                this.form.name = this.alias.name
                this.form.domain_name = this.alias.domain_name
                this.form.logo = this.alias.style.logo
                this.form.slogan = this.alias.slogan && this.alias.slogan != 'null' ? this.alias.slogan : ''
                this.form.mainColor = this.alias.style.css.defaultBgColor
                this.form.headerHideBrand = this.alias.style.headerHideBrand
                this.form.headerTextSelectedColor = this.alias.style.css.defaultTextSelected
                this.form.headerTextColor = this.alias.style.css.menuTextUnselected
                this.form.hoverHeaderTextColor = this.alias.style.css.hoverDefaultTextSelected
                this.form.host = this.alias.smtp_host ? this.alias.smtp_host : ''
                this.form.port = this.alias.smtp_port ? this.alias.smtp_port : ''
                this.form.login = this.alias.smtp_login ? this.alias.smtp_login : ''
                this.form.password = this.alias.smtp_pwd ? this.alias.smtp_pwd : ''
                this.form.secure = this.alias.smtp_secure ? this.alias.smtp_secure : ''
                this.form.from = this.alias.smtp_from ? this.alias.smtp_from : ''
                this.form.images = this.alias.images
                this.form.textes = this.alias.textes
            } else {
                this.$toast.error('Aucun espace configuré')
                this.loading = false
                return
            }

            // End loader
            this.loading = false
        },

        changeTextException(key) {
            if (this.availableTexts[key] != this.selectedLang.contenu[key]) {
                let exceptionExists = this.form.textes[this.selectedLang.lang].find((item) => item.key == key)
                if (!exceptionExists) {
                    // Ajoute une exception
                    this.form.textes[this.selectedLang.lang].push({key: key, value: this.availableTexts[key]})
                } else {
                    // Modifie une exception
                    exceptionExists.value = this.availableTexts[key]
                }
            }
        },

        async save() {
            // console.log({
            //     style: {"logo":this.form.logo,"favicon":this.alias.style.favicon,"headerHideBrand":this.form.headerHideBrand,"css":{"defaultBgColor":this.form.mainColor,"defaultTextSelected":this.form.headerTextSelectedColor,"hoverDefaultTextSelected":this.form.hoverHeaderTextColor,"menuTextUnselected":this.form.headerTextColor,"headerLogoHeight":null}},
            //     images: this.form.images,
            //     textes: this.form.textes,
            //     smtp_host: this.form.host,
            //     smtp_login: this.form.login,
            //     smtp_pwd: this.form.password,
            //     smtp_port: this.form.port,
            //     smtp_secure: this.form.secure,
            //     smtp_from: this.form.from,
            //     slogan: this.form.slogan,
            // });
            this.loadingEdit = true
            let response = await this.$request.post('selfcare.edit-alias', {
                style: JSON.stringify({"logo":this.form.logo,"favicon":this.alias.style.favicon,"headerHideBrand":this.form.headerHideBrand ? 1 : 0,"css":{"defaultBgColor":this.form.mainColor,"defaultTextSelected":this.form.headerTextSelectedColor,"hoverDefaultTextSelected":this.form.hoverHeaderTextColor,"menuTextUnselected":this.form.headerTextColor,"headerLogoHeight":null}}),
                images: JSON.stringify(this.form.images),
                textes: JSON.stringify(this.form.textes),
                smtp_host: this.form.host,
                smtp_login: this.form.login,
                smtp_pwd: this.form.password,
                smtp_port: this.form.port,
                smtp_secure: this.form.secure,
                smtp_from: this.form.from,
                slogan: this.form.slogan,
            })
            if (response.data.posts.post.id) {
                this.$toast.success('Espace modifié')
            }
            this.loadingEdit = false
        }
    },

    mounted () {
        this.getAlias()
    },
}
</script>

<style lang="scss" scoped>

</style>