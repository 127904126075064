<template>
    <div>
        <!-- Modal edit categ -->
        <modal name="modal-edit-add-categ" height="auto" :scrollable="true">
            <div class="p-10" v-if="formCateg">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">
                            <template v-if="!formCateg.id">{{$tt("Créer votre catégorie")}}</template>
                            <template v-if="formCateg.id">{{$tt("Modifier une catégorie")}}</template>
                        </h2>
                    </div>
                    <button @click="$modal.hide('modal-edit-add-categ')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <template v-if="typeEntite == 5 || typeEntite == 3 || typeEntite == 6">
                        <div class="min-h-[500px]">
                            <input-rule v-model="formCateg.libelle" type="text" :label="$tt('Libellé de votre catégorie de produit')" :label-inline="false" id="new-categ-libelle" />
                            <div v-if="loadingGetDistriCategs" class="p-3 mt-2"><spinner :size="10" /></div>
                            <input-rule v-if="!loadingGetDistriCategs && categDistris && Object.keys(categDistris).length > 0" v-model="groupCategDistri" type="vselect" :options="{values: Object.keys(categDistris)}" :label="$tt('Catégorie Ecosystème')" :label-inline="false" class="mt-4" id="id-distri-group-categ" />
                            <input-rule v-if="!loadingGetDistriCategs && groupCategDistri" v-model="formCateg.id_distri_categ" type="vselect" :options="{values: categDistris[groupCategDistri], label: 'nom', key: 'id'}" :label="$tt('Précision de la catégorie')" :label-inline="false" class="mt-4" id="id-distri-categ" />
                            <div class="grid grid-cols-2">
                                <input-rule type="pcheck" v-model="formCateg.pro" :label="$tt('Catégorie professionnelle')" :label-inline="false" class="mt-4" />
                                <input-rule type="pcheck" v-model="formCateg.gaz" v-if="formCateg.id_distri_categ && groupCategDistri && categDistris[groupCategDistri].find((item) => item.id == formCateg.id_distri_categ)?.gaz == 1" :label="$tt('Gestion du gaz')" :label-inline="false" class="mt-4" />
                            </div>
                            <input-rule type="pcheck" v-model="formCateg.thermique" v-if="formCateg.id_distri_categ && groupCategDistri && categDistris[groupCategDistri].find((item) => item.id == formCateg.id_distri_categ)?.thermique == 1" :label="$tt('Moteur thermique')" :label-inline="false" class="mt-4" />
                            <button :disabled="loadingEditAddCateg" @click="saveEditAddCateg" class="px-4 py-2 rounded font-bold bg-green-600 hover:bg-green-700 duration-200 mt-4 text-white">
                                <spinner :size="10" v-if="loadingEditAddCateg" />
                                <template v-if="!loadingEditAddCateg">
                                    <i class="fas fa-check mr-1"></i> {{ $tt('Enregistrer') }}
                                </template>
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </modal>

        <!-- Modal accessoires -->
        <modal name="modal-accessoires" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{ $tt('Accessoires liés à la catégorie') }}
                    </h2>
                    <button @click="$modal.hide('modal-accessoires')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="flex items-end gap-8 mb-4">
                        <div class="flex grow gap-4">
                            <input-rule v-model="formNewAccessoire.designation" type="text" :label-inline="false" :label="$tt('Nom de l\'accessoire')" class="grow" />
                            <input-rule v-model="formNewAccessoire.oblig" type="pcheck" :label-inline="false" :label="$tt('Saisie obligatoire')" />
                        </div>
                        <button @click="editAddAccessoire()" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white duration-200 rounded text-xs"><i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter') }}</button>
                    </div>
                    <table class="w-full border text-sm">
                        <thead class="bg-slate-100 font-bold">
                            <tr>
                                <td class="px-2 py-1">{{$tt('Nom')}}</td>
                                <td class="px-2 py-1">{{$tt('Obligatoire')}}</td>
                                <td class="px-2 py-1">{{$tt('Actions')}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(accessoire, accessoireIndex) in accessoires" :key="'accessoire-table-' + accessoireIndex" class="border-b">
                                <td class="px-2 py-1">
                                    <template v-if="!accessoire.edit">{{ accessoire.designation }}</template>
                                    <template v-if="accessoire.edit">
                                        <input-rule v-model="accessoire.designation_inp" type="text" :label="$tt('Désignation')" :label-inline="false" />
                                    </template>
                                </td>
                                <td class="px-2 py-1">
                                    <template v-if="!accessoire.edit">
                                        <i class="fas" :class="accessoire.oblig ? 'fa-check text-green-600' : 'fa-times text-red-600'"></i>
                                    </template>
                                    <template v-if="accessoire.edit">
                                        <input-rule v-model="accessoire.oblig_inp" type="pcheck" :label="$tt('Saisie obligatoire')" :label-inline="false" />
                                    </template>
                                </td>
                                <td class="px-2 py-1">
                                    <template v-if="!accessoire.edit">
                                        <button @click="accessoire.edit = true" class="px-3 py-1.5 bg-blue-500 hover:bg-blue-600 duration-200 text-white rounded text-xs mr-1">{{ $tt('Modifier') }}</button>
                                        <button @click="deleteAccessoire(accessoire)" class="px-3 py-1.5 bg-red-500 hover:bg-red-600 duration-200 text-white rounded text-xs">{{ $tt('Supprimer') }}</button>
                                    </template>
                                    <template v-if="accessoire.edit">
                                        <button @click="editAddAccessoire(accessoire); accessoire.designation_inp = accessoire.designation; accessoire.oblig = accessoire.oblig_inp" class="px-3 py-1.5 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-xs mr-1">{{ $tt('Enregistrer') }}</button>
                                        <button @click="accessoire.edit = false" class="px-3 py-1.5 bg-slate-500 hover:bg-slate-600 duration-200 text-white rounded text-xs">{{ $tt('Annuler') }}</button>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </modal>

        <!-- Modal edit categ -->
        <modal name="modal-confirm-delete-categ" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        Confirmer la suppression
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-categ')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="text-lg">Souhaitez-vous supprimer votre categorie {{ selectedLine.libelle }} ?</div>
                    <div v-if="typeEntite == 5" class="bg-orange-100 text-orange-800 p-4 rounded mt-4 font-bold">
                        <i class="fas fa-exclamation-triangle mr-1"></i>
                        {{ $tt(('En supprimant cette catégorie, tous les tarifs liés à cette catégorie seront également supprimés')) }}
                    </div>
                    <div class="flex gap-2 items-center mt-4">
                        <button @click="$modal.hide('modal-confirm-delete-categ')" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200">Annuler <i class="fas fa-times ml-1"></i></button>
                        <button :disabled="loadingDeleteCateg" @click="deleteCateg" class="px-4 py-2 rounded text-sm bg-red-500 hover:bg-red-600 duration-200 text-white">
                            <spinner :size="10" v-if="loadingDeleteCateg" />
                            <template v-if="!loadingDeleteCateg">
                                {{$tt('Supprimer')}} <i class="fas fa-trash ml-1"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <div class="grid grid-cols-2 col-span-2 bg-blue-50 rounded border-2 border-blue-200 p-4">
            <div class="border-r-2 border-blue-200">
                <h2 class="text-lg font-bold mb-2 pb-2 border-b-2 border-blue-200 text-blue-900">{{ $tt('Groupe de catégories') }}</h2>
                <div class="p-2" v-if="loadingGetEicta">
                    <spinner :size="10" />
                </div>
                <div class="border-b border-blue-200 p-2 cursor-pointer flex items-center gap-2" :class="selectedEicta && selectedEicta.id == eicta.id ? 'bg-blue-500 text-white' : 'hover:bg-blue-400 hover:text-white duration-200'" v-for="(eicta, eictaIndex) in eictas" :key="'eicta-' + eictaIndex" @click="selectEicta(eicta)">
                    <img :src="eicta.img" v-if="eicta.img" class="h-[25px]" alt="">
                    {{ $tt(eicta.fr) }}
                </div>
            </div>
            <div>
                <h2 v-if="selectedEicta" class="text-lg mb-2 flex items-center gap-4 pb-2 border-b-2 border-blue-200 text-blue-900 pl-4">
                    <div class="text-sm bg-blue-200 px-4 py-1 text-blue-900 font-bold rounded">{{ selectedEicta.fr }}</div>
                </h2>
                <div class="pl-4">
                    <div class="font-bold mb-2">{{ $tt('Vos catégories (HG)') }} :</div>
                    <div class="p-2" v-if="loadingGetCategs">
                        <spinner :size="10" />
                    </div>
                    <template v-if="!loadingGetCategs && !loadingGetEicta">
                        <template v-if="!selectedEicta">
                            <div class="bg-white p-4 rounded text-blue-900" v-if="categs.length <= 0">
                                <div class="font-bold text-sm">{{ $tt('Aucune catégorie') }}</div>
                                <div class="font-light text-sm">{{ $tt('Séléctionner une catégorie dans le menu ci-contre') }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <button v-if="selectedEicta" @click="displayModalEditAdd({})" class="px-4 py-2 bg-blue-100 hover:bg-blue-200 duration-200 rounded text-sm mb-2"><i class="fas fa-plus mr-1"></i> {{ $tt('Créer une catégorie') }}</button>
                            <div class="max-h-[600px] overflow-y-auto">
                                <div class="border-b p-2 bg-blue-200 focus:outline-none" v-for="(categ, categIndex) in categs" :key="'selected-categ-' + categIndex">
                                    <div class="flex items-center justify-between gap-2">
                                        <span class="font-light text-sm ml-2">{{ categ.libelle }}</span>
                                        <div class="flex items-center gap-1">
                                            <modal-reparateur-manage-codes :categ="categ" />
                                            <button class="bg-blue-800 hover:bg-blue-900 duration-200 p-1.5 px-2 text-sm text-white rounded" @click="selectedLine = categ; getAccessoires(); $modal.show('modal-accessoires')"><i class="fas fa-box-open"></i> <span class="text-xs font-light">{{ categ.accessoires.length }}</span></button>
                                            <button class="bg-slate-100 hover:bg-slate-200 duration-200 p-1.5 px-2 text-sm rounded" @click="displayModalEditAdd(categ)"><i class="fas fa-pen"></i></button>
                                            <button @click="selectedLine = categ; $modal.show('modal-confirm-delete-categ')" class="bg-red-700 hover:bg-red-800 duration-200 text-white rounded text-xs p-2"><i class="fas fa-trash"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModalReparateurManageCodes from './ModalReparateurManageCodes.vue'

export default {
    components: {
        ModalReparateurManageCodes,
    },
    props: {
        typeEntite: {
            required: true
        },
    },
    data() {
        return {
            eictas: [],
            selectedEicta: null,

            formCateg: {},
            // allCategories: [],
            categs: [],

            categDistris: [],
            selectedLine: null,
            groupCategDistri: null,

            formNewAccessoire: {},
            accessoires: [],
            timerEditAccessoire: null,

            loadingGetCategs: false,
            loadingGetEicta: false,
            loadingAddEictaCateg: false,
            loadingEditAddCateg: false,
            loadingDeleteCateg: false,
            loadingGetDistriCategs: false,
            // loadingGetUnattributeCateg: false
        }
    },
    methods: {
        async getEicta() {
            this.loadingGetEicta = true
            let formData = {}
            if (this.$request.user().account.type_entite == 3) {
                let responseStation = await this.$request.post('reparateur.get-sta')
                formData = {
                    station_id: responseStation.data.posts.post.id
                }
            }
            if (this.$request.user().account.type_entite == 6) {
                formData = {
                    station_id: this.$request.user().account.id_do
                }
            }
            if (this.$request.user().account.type_entite == 5) {
                formData = {
                    station_id: this.$request.user().account.id_entite
                }
            }
            let response = await this.$request.post('categ.get-eicta', formData)
            this.loadingGetEicta = false
            this.eictas = response.data.posts.post
        },

        async getDistriCategs() {
            this.loadingGetDistriCategs = true
            let response = await this.$request.post('categ.get-distri-categs', {
                eicta_id: this.selectedEicta.id
            })
            this.loadingGetDistriCategs = false
            this.categDistris = response.data.posts.post
        },

        async selectEicta(eicta) {
            this.selectedEicta = eicta
            await this.getCategs()
            this.getDistriCategs()
        },

        async getCategs(loader = true) {
            if (this.typeEntite == 5 || this.typeEntite == 3 || this.typeEntite == 6) {
                this.loadingGetCategs = loader ? true : false
                let response = await this.$request.post('reparateur.get-categ-in-eicta', {
                    eicta_id: this.selectedEicta.id
                })
                this.loadingGetCategs = false
                this.categs = response.data.posts.post
            }
        },

        async saveEditAddCateg() {
            if (this.typeEntite == 5 || this.typeEntite == 3 || this.typeEntite == 6) {
                let validationData = []
                if (this.categDistris && Object.keys(this.categDistris).length > 0) {
                    validationData = [
                        {value: this.formCateg.libelle, rules: 'required', id: 'new-categ-libelle'},
                        {value: this.groupCategDistri, rules: 'required', id: 'id-distri-group-categ'},
                        {value: this.formCateg.id_distri_categ, rules: 'required', id: 'id-distri-categ'},
                    ]
                } else {
                    validationData = [
                        {value: this.formCateg.libelle, rules: 'required', id: 'new-categ-libelle'},
                    ]
                }
                if (!this.$validation.check(validationData)) {return}

                this.formCateg.code_group_id = this.selectedEicta.id

                if (this.formCateg.id) {
                    this.formCateg.line_id = this.formCateg.id
                    delete this.formCateg.id
                }

                this.loadingEditAddCateg = true
                let response = await this.$request.post('reparateur.edit-add-list-categ', this.formCateg)
                this.loadingEditAddCateg = false
                if (response.data.posts.post == 1) {
                    await this.getCategs(false)
                    this.$toast.success(this.$tt('Catégorie ajoutée'))
                    this.$modal.hide('modal-edit-add-categ')
                } else {
                    this.$toast.error(this.$tt('Une erreur est survenue'))
                }
            }
        },

        displayModalEditAdd(categ) {
            this.groupCategDistri = categ.groupe
            this.formCateg = categ
            this.$modal.show('modal-edit-add-categ')
        },

        async deleteCateg() {
            if (this.typeEntite == 5 || this.typeEntite == 3 || this.typeEntite == 6) {
                this.loadingDeleteCateg = true
                let response = await this.$request.post('reparateur.delete-categ', {
                    line_id: this.selectedLine.id
                })
                if (response.data.posts.post == 1) {
                    await this.getCategs()
                    this.$toast.success(this.$tt('Catégorie supprimée'))
                    this.$modal.hide('modal-confirm-delete-categ')
                }
                this.loadingDeleteCateg = false
            }
        },

        async editAddAccessoire(line = null) {
            let endData = {}
            if (!line) {
                endData = this.formNewAccessoire
            } else {
                endData = {
                    line_id: line.id,
                    designation: line.designation_inp,
                    oblig: line.oblig_inp
                }
            }
            endData.id_categ = this.selectedLine.id

            clearTimeout(this.timerEditAccessoire)
            this.timerEditAccessoire = setTimeout(async () => {
                await this.$request.post('technicien.edit-add-accessoire', endData)
                this.getAccessoires()
            }, endData.line_id ? 500 : 0)

            this.formNewAccessoire = {}
        },

        async getAccessoires() {
            let response = await this.$request.post('technicien.get-categ-accessoires', {
                id_categ: this.selectedLine.id
            })
            this.accessoires = response.data.posts.post.map((item) => ({
                ...item,
                edit: false,
                designation_inp: item.designation,
                oblig_inp: item.oblig
            }))
            this.getCategs(false)
        },

        async deleteAccessoire(accessoire) {
            await this.$request.post('technicien.delete-categ-accessoire', {
                line_id: accessoire.id
            })
            this.getAccessoires()
            this.getCategs(false)
        }
    },
    async mounted () {
        await this.getEicta()
    },
}
</script>

<style lang="scss" scoped>

</style>