<template>
    <div>
        <app-template>
            <div class="p-4 rounded shadow bg-white w-[700px] mx-auto">
                <h1 class="text-2xl font-bold border-b pb-2 mb-2">{{ $tt('Fichier à héberger') }}</h1>
                <div class="bg-slate-50 p-4">
                    <div class="font-bold">Ajouter un fichier</div>
                    <input ref="fileupload" class="border text-xs p-2 bg-white w-full mt-2" type="file" @change="loadFile">
                </div>
                <div v-if="file" class="mt-4">
                    <h2 class="mb-1"><i class="fas fa-link mr-1"></i> {{ $tt('Url du fichier') }}</h2>
                    <div class="border rounded-full bg-slate-50 flex items-center gap-1">
                        <button @click="copy" class="bg-blue-500 w-[140px] hover:bg-blue-600 duration-200 rounded-full py-2.5 text-white text-sm"><i class="fas fa-copy mr-1"></i> {{ $tt('Copier') }}</button>
                        <div class="p-2 text-ellipsis overflow-hidden">{{ file.url }}</div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import apiUploadService from '@/services/apiUpload.service'

export default {
    data() {
        return {
            file: null,
            fileTemp: null,
        }
    },
    methods: {
        async copy() {
            if (this.file) {
                await navigator.clipboard.writeText(this.file.url);
                this.$toast.success(this.$tt('Url copiée'))
            }
        },

        async loadFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.fileTemp = files[0]
            let response = await apiUploadService.post({
                files: this.fileTemp
            })
            this.file = response.data.files[0]
        }
    },
}
</script>

<style lang="scss" scoped>

</style>