<template>
    <div>
        <div class="w-[500px] mx-auto">
            <h1 class="py-4 text-xl font-bold">Notifications</h1>
            <div class="border-t py-4">
                <div class="bg-slate-100 rounded p-4 mb-4" v-for="notification in notifications" :key="notification.id">
                    <div class="text-sm font-light">{{ notification.id }}</div>
                    <div class="font-bold">{{ notification.message }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ablyService from '@/services/ably.service.js';

export default {
    data() {
        return {
            notifications: [],
            channel: null
        };
    },
    mounted() {
        // Initialiser Ably et s'abonner à un canal
        const ably = ablyService.init();
        this.channel = ably.channels.get('notifications');
        this.channel.subscribe((message) => {
            this.notifications.push({
                id: Date.now(),
                message: message.data.message
            });
        });
    },
    beforeUnmount() {
        // Se désabonner et fermer la connexion Ably
        if (this.channel) {
            this.channel.unsubscribe();
        }
        ablyService.closeConnection();
    }
};
</script>
