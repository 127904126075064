<template>
    <div>
        {{displayMessage}}
        <div v-if="authenticated" class="mt-10 text-center font-light bg-green-500 text-white p-4 rounded-xl w-1/2 mx-auto">
            <i class="fas fa-check mr-1"></i> Vous êtes authentifié
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            displayMessage: '',
            authenticated: false
        }
    },
    methods: {
        async init() {
            let urlString = window.location.href
            let paramString = urlString.split('?')[1]
            let queryString = new URLSearchParams(paramString)
            let queryRedirectParams = {}
            let endObj = {
                id: null,
                token: null,
                next: null,
                lang: null,
                session: 0,
            }
            for (let pair of queryString.entries()) {
                if (pair[0] == 'id') {
                    endObj.id = pair[1]
                } else if (pair[0] == 'token') {
                    endObj.token = pair[1]
                } else if (pair[0] == 'next') {
                    endObj.next = pair[1]
                } else if (pair[0] == 'session') {
                    endObj.session = pair[1]
                } else if (pair[0] == 'lang') {
                    endObj.lang = pair[1]
                } else {
                    queryRedirectParams[pair[0]] = pair[1]
                }
            }

            if (endObj.lang) {
                localStorage.setItem('lang', endObj.lang)
            } else {
                localStorage.setItem('lang', 'fr')
            }

            if (!endObj.token) {
                this.displayMessage = "Erreur lors de l'authentification - token manquant"
                this.$router.push({name: 'login'})
            } else {
                let response = await this.$request.post('account.get-auth-token', {
                    auth_token: endObj.token,
                })
                
                if (response.data.posts.error) {
                    if (response.data.posts.error == "token not found") {
                        this.$toast.error(this.$tt('Impossible de vous connecter'))
                        return
                    }
                    if (response.data.posts.error == "The token has expired") {
                        this.$toast.error(this.$tt('Token expiré'))
                        return
                    }
                }

                if (response.data.posts.post) {
                    if (endObj.session && endObj.session == 1) {
                        sessionStorage.setItem('account', this.$crypto.encrypt(JSON.stringify({account: response.data.posts.post, info: response.data.posts.options.infos})))                        
                    } else {
                        localStorage.setItem('account', this.$crypto.encrypt(JSON.stringify({account: response.data.posts.post, info: response.data.posts.options.infos})))
                    }

                    // setTimeout(() => {
                    //     this.authenticated = true
                    // }, 1000)
                    let queryRedirectParamsUrl = ''
                    if (Object.keys(queryRedirectParams).length > 0) {
                        queryRedirectParamsUrl += '?' + new URLSearchParams(queryRedirectParams).toString()
                    }
                    if (!endObj.next) {
                        window.location = this.getRouteByName('home')?.path + queryRedirectParamsUrl
                    }
                    if (endObj.next && endObj.next != 'auth') {
                        if (this.$router.options.routes.find((route) => route.name == endObj.next)) {
                            window.location = this.getRouteByName(endObj.next)?.path + queryRedirectParamsUrl
                        } else {
                            console.log('Route not exists')
                        }
                    }
                } else {
                    this.displayMessage = "Erreur lors de l'authentification - erreur identifiants"
                    this.$router.push({name: 'login'})
                }
            }
        },

        getRouteByName(routeName) {
            let endRoute = null
            this.$router.options.routes.forEach(route => {
                if (route.name == routeName) {
                    endRoute = route
                }
            })
            return endRoute
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>

</style>
