<template>
    <div>
        <modal name="modal-add-marque-product" height="auto" width="80%" :scrollable="true">
            <div class="p-10 min-h-[500px]">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        {{$tt('Nouvelle marque')}}
                    </h2>
                    <button @click="$modal.hide('modal-add-marque-product')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="newMarque" type="text" :label="$tt('Nouvelle marque')" :label-inline="false" />
                    <button @click="addMarque" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded mt-4 text-sm">
                        <i class="fas fa-plus mr-1"></i> Ajouter
                    </button>
                </div>
            </div>
        </modal>

        <div v-if="selectedProduct">
            <div v-if="displayTitle" class="flex justify-between items-center">
                <h1 class="text-2xl font-semibold">{{ selectedProduct['Modele'] }}</h1>
                <img v-if="selectedProduct.img" class="h-[75px] rounded-sm" :src="selectedProduct.img" />
            </div>
            <div class="mt-4 grid grid-cols-2">
                <input-rule type="pcheck" v-model="selectedProduct.actif" :label="$tt('Appareil actif sur Districloud')" :label-inline="false" />
                <input-rule type="pcheck" v-model="selectedProduct.desac_conso" :label="$tt('Appareil actif sur Selfcare')" :label-inline="false" />
            </div>
            <div class="mt-4">
                <input-rule type="vselect" v-model="selectedProduct['id_categorie']" id="categorie" :options="{values: allCategories, label: 'fr', key: 'id_categorie'}" :label="$tt('Catégorie')" :label-inline="false" />
            </div>
            <div class="grid grid-cols-2 gap-4 my-4">
                <div class="-mt-1">
                    <input-rule :label="$tt('Marque')" v-model="selectedProduct['Marque']" type="vselect" id="marque" :options="{values: marques, label: 'Marque', key: 'Marque'}" :label-inline="false" />
                    <button @click="$modal.show('modal-add-marque-product')" class="px-2 py-1 bg-slate-200 hover:bg-slate-300 rounded text-xs font-bold text-slate-500 mt-1"><i class="fas fa-plus mr-0.5"></i> Nouvelle marque</button>

<!-- 
                    <label class="text-xs font-bold" :class="marqueError ? 'text-red-500' : 'text-gray-900'">{{$tt('Marque')}}</label>
                    <v-select
                    v-model="marqueInp"
                    :options="marques"
                    class="mt-1"
                    :label="$tt('Marque')"
                    ref="testttt"
                    >
                        <template v-slot:no-options="{search}">
                            <div class="hover:bg-blue-400 hover:text-white duration-200 text-left px-5 cursor-pointer py-1.5" @click="$refs.testttt.open = false; marques.push({Marque: search}); marqueInp = {Marque: search}">
                                <i class="fas fa-plus mr-0.5 "></i> <b>{{ search }}</b>
                            </div>
                        </template>
                    </v-select>
                    <ul v-if="marqueError" class="list-disc text-red-500 ml-4 mt-2 text-xs">
                        <li>{{ marqueError }}</li>
                    </ul> -->
                </div>
                <input-rule v-model="selectedProduct['Modele']" id="modele" type="text" :label="$tt('Modèle')" :label-inline="false" custom="bg-white" />
            </div>
            <div class="grid grid-cols-2 gap-4 mb-4">
                <input-rule v-model="selectedProduct['Ean']" id="ean" type="text" :label="$tt('Ean')" :label-inline="false" custom="bg-white" />
                <input-rule v-model="selectedProduct['ref_commercial']" type="text" :label="$tt('Ref. Commerciale')" :label-inline="false" custom="bg-white" />
            </div>
            <div class="mb-4">
                <input-rule v-model="selectedProduct['Libelle']" id="libelle" type="text" :label="$tt('Libellé')" :label-inline="false" custom="bg-white" />
            </div>
            <div class="grid grid-cols-2 gap-4 mb-4">
                <!-- <input-rule type="select" v-model="newProductForm['deee']" :options="{values: [], label: 'fr', key: 'id_categorie'}" label="DEEE" :label-inline="false" custom="bg-white" /> -->
                <input-rule v-model="selectedProduct['poids']" type="text" :label="$tt('Poids')" id="poids" :label-inline="false" custom="bg-white" />
            </div>
            <div class="mb-4">
                <label class="text-xs font-bold text-gray-900">{{$tt('Alerte produit')}}</label>
                <div class="bg-slate-100 mt-2 p-2 px-4 text-sm rounded flex items-center justify-between">
                    <div v-html="selectedProduct.comment[0]?.comment || $tt('Aucun message')"></div>
                    <button @click="newAlertMsg = selectedProduct.comment[0]?.comment; $modal.show('modal-edit-alert-product')" class="px-4 py-2 bg-slate-300 hover:bg-slate-400 duration-200 rounded text-sm"><i class="fas fa-pen mr-1"></i> {{$tt('Modifier')}}</button>
                </div>
            </div>
            <div class="mb-4">
                <label class="text-xs font-bold text-gray-900">{{$tt('Documents')}}</label>
                <div class="mt-1 relative overflow-x-auto">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 border">
                        <thead class="text-xs text-gray-900 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-1.5">
                                    {{$tt('Type')}}
                                </th>
                                <th scope="col" class="px-6 py-1.5">
                                    {{$tt('Fichier')}}
                                </th>
                                <th scope="col" class="px-6 py-1.5">
                                    {{$tt('Actions')}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b">
                                <td class="px-6 py-1.5">
                                    {{$tt('Notice')}}
                                </td>
                                <td class="px-6 py-1.5">
                                    <!-- <a href="#" class="text-blue-500 hover:underline font-light"><i class="fas fa-link mr-0.5"></i> Voir le fichier</a> -->
                                    <input-rule @input="(data) => {if (data) {selectedProduct.url_notice = data.url; $forceUpdate()}}" type="file" />
                                </td>
                                <td class="px-6 py-1.5">
                                    <div v-if="selectedProduct.url_notice && selectedProduct.url_notice != '-1'" class="flex gap-1">
                                        <a target="_blank" :href="selectedProduct.url_notice" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                        <button @click="selectedProduct.url_notice = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                    </div>
                                </td>
                            </tr>
                            <tr class="bg-white border-b">
                                <td class="px-6 py-1.5">
                                    {{$tt('Photo du produit')}}
                                </td>
                                <td class="px-6 py-1.5 text-xs col-span-2">
                                    <input-rule @input="(data) => {if (data) {selectedProduct.url_photo = data.url; $forceUpdate()}}" type="file" />
                                </td>
                                <td class="px-6 py-1.5">
                                    <div v-if="selectedProduct.url_photo && selectedProduct.url_photo != '-1'" class="flex gap-1">
                                        <a target="_blank" :href="selectedProduct.url_photo" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                        <button @click="selectedProduct.url_photo = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                    </div>
                                </td>
                            </tr>
                            <tr class="bg-white border-b">
                                <td class="px-6 py-1.5">
                                    {{$tt('Vue éclatée')}}
                                </td>
                                <td class="px-6 py-1.5 text-xs col-span-2">
                                    <input-rule @input="(data) => {if (data) {selectedProduct.url_vue = data.url; $forceUpdate()}}" type="file" />
                                </td>
                                <td class="px-6 py-1.5">
                                    <div v-if="selectedProduct.url_vue && selectedProduct.url_vue != '-1'" class="flex gap-1">
                                        <a target="_blank" :href="selectedProduct.url_vue" class="px-2 py-1 bg-blue-500 hover:bg-blue-600 duration-200 text-xs text-white rounded"><i class="fas fa-eye"></i></a>
                                        <button @click="selectedProduct.url_vue = '-1'; $forceUpdate()" class="px-2 py-1 bg-red-500 hover:bg-red-600 duration-200 text-xs text-white rounded"><i class="fas fa-trash"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="border-t-2 mt-6 pt-3 flex items-center justify-end gap-2">
                <!-- <modal-procedure :selected-product="selectedProduct" /> -->
                <button v-if="displayProc" @click="$emit('display-proc')" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 cursor-pointer duration-200">
                    <i class="fas fa-code-branch mr-1"></i> {{$tt('Procédures')}}
                </button>
                <button :disabled="loadingUpdateApp" @click="updateProduct" class="px-4 py-2 rounded text-sm bg-green-200 hover:bg-green-300 cursor-pointer duration-200">
                    <spinner v-if="loadingUpdateApp" :size="10" />
                    <template v-if="!loadingUpdateApp">
                        <i class="fas fa-check mr-1"></i> {{$tt('Enregistrer')}}
                    </template>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            product: {required: true},
            allCategories: {required: true},
            marques: {required: true},
            displayTitle: {default: true},
            displayProc: {default: true}
        },
        watch: {
            product(value) {
                let productData = JSON.parse(JSON.stringify(value))
                
                if (productData) {
                    productData.desac_conso = !productData.desac_conso
                }
                this.selectedProduct = productData
                
                this.marqueInp = {Marque: this.product['Marque']}
            }
        },
        data() {
            return {
                selectedProduct: null,
                loadingUpdateApp: false,
                marqueError: null,
                marqueInp: null,
                newMarque: null,
            }
        },
        methods: {
            async updateProduct() {
                // this.selectedProduct['Marque'] = this.marqueInp['Marque']
                this.marqueError = null
                if (!this.$validation.check([
                    {value: this.selectedProduct['Ean'], rules: 'required|minLength:13|maxLength:13|num', id: 'ean'},
                    {value: this.selectedProduct['Modele'], rules: 'required', id: 'modele'},
                    {value: this.selectedProduct['Libelle'], rules: 'required', id: 'libelle'},
                    {value: this.selectedProduct['Marque'], rules: 'required', id: 'marque'},
                    {value: this.selectedProduct.id_categorie, rules: 'required', id: 'categorie'},
                    {value: this.selectedProduct.poids, rules: 'num|min:0', id: 'poids'},
                ])) {return}
                
                // if (!this.selectedProduct['Marque']) {
                //     this.marqueError = this.$tt('Champ obligatoire')
                //     return
                // }

                this.loadingUpdateApp = true
                let response = await this.$request.post('backoffice', {
                    operation: 'updateapp',
                    id_appareil: this.selectedProduct.id,
                    actif: this.selectedProduct.actif,
                    marque: this.selectedProduct['Marque'],
                    modele: this.selectedProduct['Modele'],
                    ean: this.selectedProduct['Ean'],
                    ref_com: this.selectedProduct.ref_commercial ? this.selectedProduct.ref_commercial : this.selectedProduct['Modele'],
                    libelle: this.selectedProduct['Libelle'],
                    categ: this.selectedProduct.id_categorie,
                    poids: this.selectedProduct.poids,
                    url_notice: this.selectedProduct.url_notice,
                    url_photo: this.selectedProduct.url_photo,
                    url_vue: this.selectedProduct.url_vue,
                    desac_conso: !this.selectedProduct.desac_conso ? 1 : 0
                }, 'districloud_no_action')
                this.loadingUpdateApp = false
                if (response.data.posts.post == 1) {
                    this.$emit('submit', this.selectedProduct)
                    this.$toast.success(this.$tt('Appareil modifié'))
                }
            },

            addMarque() {
                this.marques.push(({'Marque': this.newMarque}))
                this.selectedProduct['Marque'] = this.newMarque
                this.newMarque = null
                this.$modal.hide('modal-add-marque-product')
                this.$forceUpdate()
            }
        },
        mounted () {
            let productData = JSON.parse(JSON.stringify(this.product))
            if (productData) {
                productData.desac_conso = !productData.desac_conso
            }
            this.selectedProduct = productData
            // this.marqueInp = this.product['Marque']
        },
    }
</script>

<style lang="scss" scoped>

</style>