<template>
    <div>
        <input-rule v-model="form.min_time" type="time" :label="$tt('Heure de début')" :tooltip="$tt('Heure à laquelle le calendrier commencera')" :label-inline="false" class="mb-2" id="inp-min_time" />
        <input-rule v-model="form.max_time" type="time" :label="$tt('Heure de fin')" :tooltip="$tt('Heure à laquelle le calendrier terminera')" :label-inline="false" class="mb-2" id="inp-max_time" />
        <input-rule v-model="form.interval" type="select" :label="$tt('Interval')" :options="{values: intervals, label: 'label', key: 'value'}" :label-inline="false" class="mb-2" id="inp-interval" />
        <button @click="sendForm" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm">{{ $tt('Enregistrer') }}</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                min_time: null,
                max_time: null,
                interval: null
            },

            intervals: [{"label":"00:05","value":"00:05:00"},{"label":"00:10","value":"00:10:00"},{"label":"00:15","value":"00:15:00"},{"label":"00:20","value":"00:20:00"},{"label":"00:25","value":"00:25:00"},{"label":"00:30","value":"00:30:00"},{"label":"00:35","value":"00:35:00"},{"label":"00:40","value":"00:40:00"},{"label":"00:45","value":"00:45:00"},{"label":"00:50","value":"00:50:00"},{"label":"00:55","value":"00:55:00"},{"label":"01:00","value":"00:60:00"}]
        }
    },

    methods: {
        async getConf() {
            let response = await this.$request.send('planning.get-conf')
            this.form.min_time = response.min_time
            this.form.max_time = response.max_time
            this.form.interval = response.interval
        },

        async sendForm() {
            if (!this.$validation.check([
                {value: this.form.min_time, rules: 'required', id: 'inp-min_time'},
                {value: this.form.max_time, rules: 'required', id: 'inp-max_time'},
                {value: this.form.interval, rules: 'required', id: 'inp-interval'},
            ])) {return}
            let response = await this.$request.send('planning.edit-conf', this.form)
            if (response == 1) {
                this.$toast.success(this.$tt('Planning modifié'))
            }
        }
    },

    mounted() {
        this.getConf()
    }
}
</script>