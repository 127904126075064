<template>
    <div>
        <div v-if="data">
            <input-rule v-model="inpUrl" @input="reset" :placeholder="'https://' + data.domain_name + '/form'" type="text" :label="$tt('Url de la page')" :label-inline="false" :tooltip="$tt('Aller sur votre selfcare et copier l\'url de la page qui contient le texte que vous souhaitez modifier')" />

            <div class="mt-4" v-if="inpUrl && !selectedText">
                <input-rule v-model="inpSearchText" placeholder="Rechercher un texte" type="text" :label="$tt('Rechercher un texte')" :label-inline="false" :tooltip="$tt('Commencer à écrire le texte que vous souhaitez modifier')" />
            </div>
            <div v-if="!selectedText && editListTexts && Object.keys(editListTexts).length != 0">
                <div class="font-bold text-xs mt-4">{{ $tt('Séléctionner le texte à modifier') }}</div>
                <div class="mt-2">
                    <div v-for="(txt, txtIndex) in editListTexts" :key="'txt-' + txtIndex" @click="selectedText = txt; selectedKey = txtIndex" class="p-2 duration-200 cursor-pointer text-sm bg-slate-200 hover:bg-blue-300 rounded mb-2">
                        <p v-html="txt"></p>
                    </div>
                </div>
            </div>

            <div class="mt-4" v-if="selectedText">
                <button class="text-xs bg-slate-200 hover:bg-slate-300 duration-200 px-2 py-1 rounded" @click="reset"><i class="fas fa-arrows-rotate mr-1"></i> {{ $tt('Autre texte') }}</button>
                <div class="font-bold text-xs mt-4">{{ $tt('Modifier votre texte') }}</div>
                <input-rule class="mt-2" type="text" v-model="selectedText" :label-inline="false" />
                <button :disabled="loadingEdit" @click="saveText" class="bg-green-500 hover:bg-green-600 duration-200 text-white rounded mt-4 px-4 py-2 text-sm">
                    <spinner v-if="loadingEdit" :size="10" />
                    <template v-if="!loadingEdit">
                        <i class="fas fa-check mr-1"></i> Modifier
                    </template>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    watch: {
        inpSearchText() {
            this.searchText()
        }
    },

    props: {
        data: {
            required: true,
        }
    },
    
    data() {
        return {
            inpSearchText: null,
            inpUrl: null,
            textLangs: [],
            texts: [],
            editListTexts: [],
            selectedText: null,
            selectedKey: null,
            listPage: [],
            keywords: [
                {label: "Accueil", key: "accueil", url: "/"},
                {label: "Bienvenue", key: "bienvenue", url: "/bienvenue"},
                {label: "Formulaire", key: "form", url: "/form",},
                {label: "Inscription", key: "inscription", url: "/utilisateur/inscription"},
                {label: "Mes produits", key: "mes_produits", url: "/utilisateur/produit/liste"},
                {label: "Paramètres", key: "parametres", url: "/utilisateur/modifier"},
                {label: "Template", key: "template", url: "*"},
            ],

            loadingEdit: false,
        }
    },

    methods: {
        async getTexts() {
            let aliasResponse = await this.$request.post('selfcare.get-alias')
            let alias = aliasResponse.data.posts.post
            // Get texts
            this.textLangs = []
            this.texts = []
            this.$nextTick(async () => {
                let responseText = await this.$request.post('selfcare.get-textes')
                this.textLangs = responseText.data.posts.post
                for (let exceptionLang in alias.textes) {
                    let langTexts = this.textLangs.find((langItem) => langItem.lang == exceptionLang)
                    if (langTexts) {
                        langTexts = langTexts.contenu
                        let exceptionTexts = alias.textes[exceptionLang]
                        exceptionTexts.forEach((exception) => {
                            langTexts[exception.key] = exception.value
                        })
                    }
                }
    
                this.texts = this.textLangs[0]
                this.getListPage()
            })
        },

        getListPage() {
            this.listPage = null
            let items = {}
            let findEntriesStartingWith = (obj, prefix) => {
                return Object.fromEntries(
                    // eslint-disable-next-line
                    Object.entries(obj).filter(([key, _]) => key.startsWith(prefix))
                );
            }

            if (this.texts && this.inpUrl) {
                let splitUrl = this.inpUrl.split(this.data.domain_name)
                if (splitUrl.length > 1) {
                    splitUrl.shift()
                }

                let keyword = this.keywords.find((item) => item.url == splitUrl[0])
                // console.log(keyword);
                
                if (this.texts.contenu) {
                    items = findEntriesStartingWith(this.texts.contenu, keyword.key)
                }
            }
            this.listPage = items
            console.log(this.texts);
            console.log(this.listPage);
            
        },

        searchText() {
            this.editListTexts = {}
            if (this.inpSearchText) {
                for (const key in this.listPage) {
                    if (this.listPage[key].toLowerCase().startsWith(this.inpSearchText.toLowerCase())) {                        
                        this.editListTexts[key] = this.listPage[key]
                    }
                }
            }
        },

        reset() {
            this.editListTexts = []
            this.selectedText = null
            this.inpSearchText = null
            this.selectedKey = null
            this.getListPage()
        },

        async saveText() {
            this.loadingEdit = true
            await this.$request.send('selfcare.edit-text', {
                key: this.selectedKey,
                text: this.selectedText
            })
            this.loadingEdit = false
            this.reset()
            await this.getTexts()
            this.getListPage()
            this.$toast.success(this.$tt('Texte modifié'))
        }
    },

    mounted() {
        this.getTexts()
    }
}
</script>