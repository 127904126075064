<template>
    <div>
        <app-template>
            <div class="bg-white p-10 rounded-xl border shadow-sm mt-10 mb-16">
                <h1 class="text-3xl font-black">Districloud</h1>
                <p class="font-thin mt-2 text-lg">Bienvenue sur l'extension de Districloud.</p>
            </div>
        </app-template>
    </div>
</template>

<script>
export default {
    name: 'Home',

    methods: {
        onFileChange: function(e) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = e => {
                let lines = e.target.result
                lines = lines.replaceAll(';', '')
                lines = lines.replaceAll('\r', '')
                lines = lines.split('\n')
                lines.shift()
                console.log(lines.join(';'));
            }
            reader.readAsText(file);
        },
    },
}
</script>
