<template>
    <div>
        <app-template>
            <div class="text-right mb-4">
                <button @click="init" class="px-4 py-2 bg-orange-500 text-xl font-bold text-white rounded">
                    <template v-if="loading">
                        <spinner :size="10" />
                    </template>
                    <template v-else>
                        Refresh
                    </template>
                </button>
            </div>
            <div class="bg-white p-4 rounded">
                <div v-if="displayCalendar">
                    <FullCalendar
                    class="h-[700px]"
                    ref="calendarDay"
                    :options="calendarOptions"
                    />
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'; // Pour Vue 2
import frLocale from '@fullcalendar/core/locales/fr';
import timeGridPlugin from '@fullcalendar/timegrid'; // Import du plugin

export default {
    components: {
        FullCalendar
    },
    props: {
        selectedData: {},
        events: []
    },
    watch: {
        selectedData: {
            deep: true,
            handler() {
                this.setDate()
            }
        }
    },
    data() {
        return {
            loading: false,
            confPlanning: { "id": 2, "id_sta": 18, "min_time": "08:00:00", "max_time": "23:00", "interval": "00:15:00" },
            displayCalendar: false,
            calendarOptions: {
                plugins: [timeGridPlugin],
                locales: [ frLocale ],
                locale: 'fr',
                timeZone: 'Europe/Paris',
                initialView: 'timeGridDay',
                events: [],
                allDaySlot: false,
                views: {
                    timeGridDay: {
                        slotDuration: '00:15:00', // Intervalles de 15 minutes
                        slotLabelInterval: '00:15:00',
                        slotMinTime: '08:00:00',
                        slotMaxTime: '18:00:00',
                    },
                },
                schedulerLicenseKey: '0064802622-fcs-1734624337',
                slotLabelFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: false
                },
            }
        }
    },
    methods: {
        async init() {
            this.loading = true
            if (this.confPlanning) {
                this.calendarOptions.views.timeGridDay.slotDuration = this.confPlanning.interval
                this.calendarOptions.views.timeGridDay.slotLabelInterval = this.confPlanning.interval
                this.calendarOptions.views.timeGridDay.slotMinTime = this.confPlanning.min_time
                this.calendarOptions.views.timeGridDay.slotMaxTime = this.confPlanning.max_time
            }
            this.displayCalendar = true

            this.$nextTick(() => {
                this.highlightHours();
            })
            
            await this.getEvents()
            this.loading = false
        },
        
        async getEvents() {
            let reponse = await this.$request.send('opti.nearest-routes2', {
                id_dossier: 152763
            })
            let index = 0
            this.calendarOptions.events = reponse[index]
            console.log(reponse[index][0].date_debut);
            
            this.$nextTick(() => {
                this.setDate()
            })
        },

        setDate() {
            if (this.$refs.calendarDay) {
                const calendarApi = this.$refs.calendarDay.getApi();
                calendarApi.gotoDate(this.calendarOptions.events[0].date_debut);
            }
        },

        highlightHours() {
            // const labels = this.$refs.calendarDay.$el.querySelectorAll('.fc-timegrid-slot-label-cushion');

            // labels.forEach((label) => {
            //     const text = label.textContent || label.innerText;
            //     console.log(text);
                

            //     // Vérifie si l'heure est une heure principale (se termine par ":00")
            //     if (text.endsWith(':00')) {
            //         label.classList.add('hour-label'); // Ajoute une classe personnalisée
            //         label.innerHTML = text.split(':')[0] + 'h'
            //     } else {
            //         label.classList.add('minute-label'); // Ajoute une classe pour les minutes
            //         label.innerHTML = text.split(':')[1]
            //     }
            // });
        },
    },
    mounted() {
        this.init()
    }
}
</script>

<style>
.fc-col-header-cell-cushion {
    font-size: 14px;
}

/* Styliser les heures */
.hour-label {
    font-size: 0.9em !important;
    font-weight: bold;
}

/* Styliser les minutes */
.minute-label {
    font-size: 0.7em !important;
    font-weight: normal;
    color: #888 !important; /* Texte plus clair pour les minutes */
}
</style>