<template>
    <div>
        <template v-if="!isWidget">
            <!-- Large screen -->
            <div
            @mouseover="isMinimized = false"
            @mouseleave="isMinimized = minimize"
            :class="isMinimized ? 'w-[50px]' : 'w-[200px]'"
            class="fixed left-0 top-0 bottom-0 flex flex-col text-white duration-100 hidden md:block"
            style="background: #1a1e2e;"
            >
                <h1 v-if="!isMinimized" class="text-xl font-black mt-4 p-4">
                    <div>DistriCloud</div>
                </h1>

                <div v-if="isMinimized">
                    <img class="mx-auto my-7 rounded h-[20px]" src="https://www.districloud.net/img/favicon.ico" alt="">
                </div>
                <template v-for="(menuItem, menuItemIndex) in menuItems">
                    <template v-if="!menuItem.permission || $permissionTool.hasPermission(menuItem.permission)">
                        <div @click="menuItemClick(menuItem)" :class="isSelectedRoute(menuItem) ? 'text-green-400' : 'opacity-60 hover:opacity-100'" class="duration-200 cursor-pointer" :key="'menu-' + menuItemIndex">
                            <div class="flex items-center px-4 py-3">
                                <i class="text-xs mr-2" :class="menuItem.icon"></i>
                                <div v-if="!isMinimized" class="font-light text-sm">{{ menuItem.label }}</div>
                            </div>
                        </div>
                    </template>
                    <!-- Childs -->
                    <template v-if="menuItem.open && menuItem.childs && !isMinimized">
                        <template v-for="(childMenuItem, childMenuItemIndex) in menuItem.childs">
                            <div class="ml-4 pl-4 border-l-2 border-gray-700" :key="'child-menu-' + menuItemIndex + '-' + childMenuItemIndex" v-if="!childMenuItem.permission || $permissionTool.hasPermission(childMenuItem.permission)">
                                <div @click="menuItemClick(childMenuItem)" :class="isSelectedRoute(childMenuItem) ? 'text-green-400' : 'opacity-60 hover:opacity-100'" class="duration-200 cursor-pointer">
                                    <div class="flex items-center py-1">
                                        <i v-if="childMenuItem.icon" class="text-xs mr-2" :class="childMenuItem.icon"></i>
                                        <div v-if="!isMinimized" class="font-thin text-sm">{{ childMenuItem.label }}</div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>
                <div v-if="!isMinimized" class="p-4 fixed bottom-0" :class="isMinimized ? 'w-[50px]' : 'w-[200px]'">
                    <div v-if="account" class="text-sm font-bold lowercase opacity-90">{{ account.email }}</div>
                    <div>
                        <a class="text-xs font-thin underline" href="/logout">
                            {{ $tt('Se déconnecter') }}
                        </a>
                    </div>
                    <div class="mt-2 flex items-center justify-between grow">
                        <button @click="changeLang('fr')"><img :class="selectedLang == 'fr' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Flag_of_France.png/1280px-Flag_of_France.png" alt=""></button>
                        <button @click="changeLang('en')"><img :class="selectedLang == 'en' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Flag_of_the_United_Kingdom.png/1200px-Flag_of_the_United_Kingdom.png" alt=""></button>
                        <button @click="changeLang('de')"><img :class="selectedLang == 'de' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/1280px-Flag_of_Germany.svg.png" alt=""></button>
                        <button @click="changeLang('es')"><img :class="selectedLang == 'es' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png" alt=""></button>
                        <button @click="changeLang('it')"><img :class="selectedLang == 'it' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Italy_%282003%E2%80%932006%29.svg/220px-Flag_of_Italy_%282003%E2%80%932006%29.svg.png" alt=""></button>
                    </div>
                </div>
                <div v-if="isMinimized" class="p-4 fixed bottom-0">
                    <div v-if="account" class="text-sm font-bold lowercase opacity-90 text-center bg-white/20 rounded-full w-[20px]">{{ account.email[0] }}</div>
                </div>
            </div>
            <div v-if="$slots.submenu" :class="isMinimized ? 'left-[50px]' : 'left-[200px]'" class="hidden md:block duration-100 bg-white fixed top-0 bottom-0 w-[285px] border-r border-slate-200 overflow-y-auto">
                <slot name="submenu"></slot>
            </div>
            <div class="hidden md:block fixed right-0 top-0 bottom-0 overflow-y-auto duration-100" :class="marginSlotContent" id="template-content">
                <div class="px-5">
                    <!-- Breadcrumb -->
                    <nav class="flex py-4" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-3">
                            <li class="inline-flex items-center">
                                <button @click="$router.push({name: 'home'})" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600">
                                    <svg aria-hidden="true" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    {{ $tt('Accueil') }}
                                </button>
                            </li>
                            <li v-for="(breadcrumb, breadcrumbIndex) in breadcrumbs" :key="'breadcrumb-item-' + breadcrumbIndex" aria-current="page">
                                <div class="flex items-center">
                                    <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2">
                                        <i v-if="breadcrumb.icon" class="mr-1" :class="breadcrumb.icon"></i>
                                        {{ breadcrumb.label }}
                                    </span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div>
                        <slot></slot>
                    </div>
                </div>
            </div>


            <!-- Small screen -->
            <div class="md:hidden">
                <div class="bg-white fixed top-0 left-0 right-0 p-2 px-5 bg-blur flex justify-between items-center">
                    <div class="flex items-center gap-2">
                        <img class="rounded h-[20px]" src="https://www.districloud.net/img/favicon.ico" alt="">
                        <h1 class="font-light opacity-60 text-xs"><div>DistriCloud</div></h1>
                    </div>
                    <button @click="displayMobileMenu = true">
                        <i class="fas fa-bars text-3xl text-gray-500"></i>
                    </button>
                </div>
                <div v-if="displayMobileMenu">
                    <div @click="displayMobileMenu = false" class="z-20 bg-black bg-opacity-70 fixed top-0 left-0 right-0 bottom-0"></div>
                    <div class="bg-white fixed top-0 bottom-0 left-0 right-20 p-4 z-20 overflow-y-auto">
                        <div class="flex items-center my-7 gap-4">
                            <img class="rounded h-[20px]" src="https://www.districloud.net/img/favicon.ico" alt="">
                            <h1 class="text-xl font-black"><div>DistriCloud</div></h1>
                        </div>
                        <div class="mb-16">
                            <template v-for="(menuItem, menuItemIndex) in menuItems">
                                <template v-if="!menuItem.permission || $permissionTool.hasPermission(menuItem.permission)">
                                    <div @click="menuItemClick(menuItem)" :class="isSelectedRoute(menuItem) ? 'text-green-400' : 'opacity-60 hover:opacity-100'" class="duration-200 cursor-pointer" :key="'menu-' + menuItemIndex">
                                        <div class="flex items-center px-4 py-3">
                                            <i class="text-xs mr-2" :class="menuItem.icon"></i>
                                            <div class="font-light text-sm">{{ menuItem.label }}</div>
                                        </div>
                                    </div>
                                </template>
                                <!-- Childs -->
                                <template v-if="menuItem.open && menuItem.childs">
                                    <template v-for="(childMenuItem, childMenuItemIndex) in menuItem.childs">
                                        <div class="ml-4 pl-4 border-l-2 border-gray-700" :key="'child-menu-' + childMenuItemIndex" v-if="!childMenuItem.permission || $permissionTool.hasPermission(childMenuItem.permission)">
                                            <div @click="menuItemClick(childMenuItem)" :class="isSelectedRoute(childMenuItem) ? 'text-green-400' : 'opacity-60 hover:opacity-100'" class="duration-200 cursor-pointer">
                                                <div class="flex items-center py-1">
                                                    <i v-if="childMenuItem.icon" class="text-xs mr-2" :class="childMenuItem.icon"></i>
                                                    <div class="font-thin text-sm">{{ childMenuItem.label }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </div>
                        <div class="p-4 fixed bottom-0">
                            <div v-if="account" class="text-sm font-bold lowercase opacity-90">{{ account.email }}</div>
                            <a class="text-xs font-thin underline" href="/logout">
                                {{ $tt('Se déconnecter') }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="$slots.submenu" class="md:hidden fixed top-[52px] bg-white left-0 right-0 overflow-y-auto max-h-screen" style="z-index: 10">
                <div class="py-3 text-white px-4 text-sm font-light" @click="displayMobileSubmenu = !displayMobileSubmenu" :class="displayMobileSubmenu ? 'bg-red-600' : 'bg-slate-800'">
                    <template v-if="displayMobileSubmenu"><i class="fas fa-times mr-1"></i> Fermer le menu</template>
                    <template v-if="!displayMobileSubmenu"><i class="fas fa-bars mr-1"></i> Ouvrir le menu</template>
                </div>
                <template v-if="displayMobileSubmenu">
                    <slot name="submenu"></slot>
                </template>
            </div>
            <div class="mt-[67px] md:hidden">
                <div class="h-screen p-3 overflow-y-auto">
                    <slot></slot>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="$slots.submenu" class="duration-100 bg-white fixed top-0 left-0 bottom-0 w-[285px] border-r border-slate-200 overflow-y-auto">
                <slot name="submenu"></slot>
            </div>
            <div :class="$slots.submenu ? 'fixed top-0 left-[285px] right-0 bottom-0 overflow-y-auto px-4' : 'overflow-y-auto h-screen'">
                <slot></slot>
            </div>
        </template>
    </div>
</template>

<script>
import apiService from '@/services/api.service';

export default {
    props: {
        pageName: {},
        minimize: {default: true}
    },

    watch: {
        minimize(value) {
            this.isMinimized = value
        }
    },

    computed: {
        marginSlotContent() {
            if (this.$slots.submenu) {
                return this.isMinimized ? 'app-container-with-submenu-minimize' : 'app-container-with-submenu'
            } else {
                return this.isMinimized ? 'app-container-without-submenu-minimize' : 'app-container-without-submenu'
            }
        },

        breadcrumbs() {
            let route = this.$route.name
            let selectedItem = null

            this.menuItems.forEach(item => {
                if (item.route && item.route == route) {
                    selectedItem = [item]
                }
                if (item.childs) {
                    item.childs.forEach(subItem => {
                        if (subItem.route && subItem.route == route) {
                            selectedItem = [item, subItem]
                        }
                    })
                }
            })
            return selectedItem
        },

        isWidget() {
            return this.$route.meta?.widget ? 1 : 0;
        }
    },

    data() {
        return {
            isMinimized: false,
            displayMobileMenu: false,
            displayMobileSubmenu: false,
            account: null,
            selectedLang: 'fr',
            menuItems: [
                {label: this.$tt('Accueil'), icon: 'fas fa-home', route: 'home'},
                {label: this.$tt('Ecosysteme'), icon: 'fas fa-leaf', permission: 'ecosystem',
                    childs: [
                        {label: this.$tt('Dossiers'), icon: 'fas fa-folder', route: 'ecosystem.folders', permission: 'ecosystem'},
                        {label: this.$tt('Envoyer un dossier'), icon: 'fas fa-folder-plus', route: 'ecosystem.send-folder', permission: 'ecosystem'},
                        {label: this.$tt('Catégories'), icon: 'fas fa-list', route: 'ecosystem.categories', permission: 'ecosystem'},
                        {label: this.$tt('Catalogue'), icon: 'fas fa-book', route: 'ecosystem.catalog', permission: 'ecosystem'},
                        {label: this.$tt('Connexion'), icon: 'fas fa-plug', route: 'ecosystem.connect', permission: 'ecosystem'},
                        {label: this.$tt('Partenaires'), icon: 'fas fa-users', route: 'ecosystem.partners', permission: 'admin'},
                        {label: this.$tt('Paiements'), icon: 'fas fa-euro', route: 'ecosystem.paiement-history', permission: 'ecosystem'},
                    ]
                },
                {label: this.$tt('Gestion des avoirs'), icon: 'fas fa-euro', permission: 'distriboard',
                    childs: [
                        {label: this.$tt('Créer un avoir'), icon: 'fas fa-plus', route: 'distriboard.make-avoir', permission: 'distriboard'},
                        {label: this.$tt('Mes avoirs'), icon: 'fas fa-file-import', route: 'distriboard.list-avoir', permission: 'distriboard'},
                    ]
                },
                {label: this.$tt('Base de connaissances'), icon: 'fas fa-book', route: 'bdc.list', permission: 'bdc'},
                {label: this.$tt('Conversions'), icon: 'fas fa-arrows-spin', permission: 'conversion_shoppingfeed',
                    childs: [
                        {label: this.$tt('ShoppingFeed'), route: 'conversion.shopping-feed', permission: 'conversion_shoppingfeed'},
                    ]
                },
                {label: this.$tt('Site vitrine'), icon: 'fas fa-window-maximize', permission: 'site_vitrine',
                    childs: [
                        {label: this.$tt('Articles'), icon: 'fas fa-pen', route: 'vitrine.articles', permission: 'site_vitrine'},
                        {label: this.$tt('Prix'), icon: 'fas fa-euro', route: 'vitrine.pricing', permission: 'site_vitrine'},
                        {label: this.$tt('Tarif simulations'), icon: 'fas fa-users-rays', route: 'vitrine.simulations', permission: 'site_vitrine'},
                    ]
                },
                {label: this.$tt('Admin entreprise'), icon: 'fas fa-building',
                    childs: [
                        {label: this.$tt('Produits'), icon: 'fas fa-book', route: 'apps.list', permission: 'admin_client'},
                        {label: this.$tt('Procédures'), icon: 'fas fa-code-branch', route: 'procedure', permission: 'admin_client'},
                        {label: this.$tt('Catégories'), icon: 'fas fa-layer-group', route: 'manage-categories', permission: 'admin_client'},
                        {label: this.$tt('Connexions'), icon: 'fas fa-bezier-curve', route: 'client.admin.liaisons', permission: 'admin_client'},
                        {label: this.$tt('Entreprise'), icon: 'fas fa-building', route: 'client.admin.entite'},
                        {label: this.$tt('Utilisateurs'), icon: 'fas fa-users', route: 'client.admin.users', permission: 'admin_client'},
                        {label: this.$tt('Modules'), icon: 'fas fa-puzzle-piece', route: 'client.admin.facturation'},
                        {label: this.$tt('Transporteurs'), icon: 'fas fa-truck', route: 'transporteur.edit', permission: 'admin_client'},
                        {label: this.$tt('Selfcare'), icon: 'fas fa-mobile', route: 'selfcare.edit', permission: 'admin_client'},
                        {label: this.$tt('Abonnement'), icon: 'fas fa-euro', route: 'client.admin.pricing-recap'},
                    ]
                },
                {label: this.$tt('Facturation'), icon: 'fas fa-file-lines', permission: 'admin',
                    childs: [
                        {label: this.$tt('Montants période'), icon: 'fas fa-chart-line', route: 'facturation.client-list', permission: 'admin'},
                        {label: this.$tt('Clients'), icon: 'fas fa-users', route: 'facturation.edit-client-options', permission: 'admin'},
                        {label: this.$tt('Modules'), icon: 'fas fa-puzzle-piece', route: 'facturation.manage-options', permission: 'admin'},
                        {label: this.$tt('Connexions'), icon: 'fas fa-users-line', route: 'facturation.waiting-connexion', permission: 'admin'},
                    ]
                },
                {label: this.$tt('Administration'), icon: 'fas fa-cog', permission: 'admin',
                    childs: [
                        // {label: this.$tt('Clients'), icon: 'fas fa-users', route: 'client.list', permission: 'admin'},
                        {label: this.$tt('Emails'), icon: 'fas fa-at', route: 'email.list', permission: 'admin'},
                        {label: this.$tt('Accès utilisateurs'), icon: 'fas fa-users', route: 'permissions.users', permission: 'admin'},
                        {label: this.$tt('Liaisons'), icon: 'fas fa-network-wired', route: 'liaisons.manage', permission: 'admin'},
                        {label: this.$tt('Inscriptions'), icon: 'fas fa-unlock-keyhole', route: 'inscription.claim', permission: 'admin'},
                    ]
                },
                {label: this.$tt('Outils'), icon: 'fas fa-toolbox', permission: 'admin',
                    childs: [
                        {label: this.$tt('Permissions'), icon: 'fas fa-lock', route: 'permissions.list', permission: 'admin'},
                        {label: this.$tt('Erreurs serveur'), icon: 'fas fa-bug', route: 'errors.list', permission: 'admin'},
                        {label: this.$tt('Logs serveur'), icon: 'fas fa-bug', route: 'admin.logs', permission: 'admin'},
                        {label: this.$tt('Ecosysteme Admin'), icon: 'fas fa-leaf', route: 'ecosystem.list', permission: 'admin'},
                        {label: this.$tt('Monitoring'), icon: 'fas fa-server', route: 'admin.monitoring', permission: 'admin'},
                        {label: this.$tt('Invitations'), icon: 'fa-brands fa-mendeley', route: 'admin.invitations', permission: 'admin'},
                        {label: this.$tt('Upload fichier'), icon: 'fas fa-file', route: 'admin.upload-file', permission: 'admin'},
                        {label: 'Selfcare admin', icon: 'fas fa-mobile-screen', url: 'https://savinfo-admin.savlog.net/alias', permission: 'admin'},
                    ]
                },
                {label: this.$tt('Ma station'), icon: 'fas fa-shop', permission: 'station',
                    childs: [
                        {label: this.$tt('Techniciens'), icon: 'fas fa-users', route: 'reparateur.techniciens', permission: 'station'},
                        {label: this.$tt('Dossiers'), icon: 'fas fa-folder', route: 'reparateur.facturation', permission: 'station'},
                        {label: this.$tt('Stock'), icon: 'fas fa-box', route: 'reparateur.stock', permission: 'station'},
                        {label: this.$tt('Configuration'), icon: 'fas fa-cog', route: 'reparateur.conf', permission: 'station'},
                    ]
                },
                // {label: 'Comptes', icon: 'fas fa-user-plus', route: 'account.claims.list', permission: 'admin'},
            ]
        }
    },

    methods: {
        initMenu() {
            this.menuItems = this.menuItems.map(v => ({...v, open: false}))

            this.menuItems.forEach((menuItem) => {
                if (menuItem.childs && menuItem.childs.map((r) => { return r.route}).find((r2) => r2 == this.$route.name)) {
                    menuItem.open = true
                }
            })

            this.isMinimized = this.minimize
        },

        isSelectedRoute(route) {
            const currentRoute = this.$route.name

            if (route.route && route.route == currentRoute) {
                return true
            }
            if (route.childs && route.childs.map((r) => { return r.route}).find((r2) => r2 == currentRoute)) {
                return true
            }
            return false
        },

        menuItemClick(menuItem) {
            if (menuItem.route) {
                let nextRoute = this.$router.options.routes.find((route) => route.name == menuItem.route)
                if (nextRoute) {
                    location.href = nextRoute.path
                    // this.$router.push({name: menuItem.route})
                }
            } else if (menuItem.url) {
                window.open(
                    menuItem.url,
                    '_blank' // <- This is what makes it open in a new window.
                );
            } else {
                this.menuItems.forEach((menu) => {
                    menu.open = false
                })
                menuItem.open = !menuItem.open
            }
        },

        async getAccount() {
            let response = await apiService.post('account.get')
            this.account = response.data.posts.post
        },

        changeLang(lang) {
            this.$lang.setLang(lang)
            location.reload()
        }
    },

    async mounted() {
        let body = document.body
        body.classList.add("bg-slate-100")
        this.selectedLang = localStorage.getItem('lang')

        this.initMenu()
        this.getAccount()

        this.$eventHub.$on('close-mobile-submenu', () => {
            this.displayMobileSubmenu = false
        })
    }
}
</script>

<style>
.app-container-with-submenu {
    left: calc(200px + 285px);
}

.app-container-without-submenu {
    left: calc(200px);
}

.app-container-with-submenu-minimize {
    left: calc(50px + 285px);
}

.app-container-without-submenu-minimize {
    left: calc(50px);
}

.vm--overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}
</style>
