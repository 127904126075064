<template>
    <div>
        <!-- Modal opti days -->
        <modal name="modal-opti-trajets" height="auto" :scrollable="true" width="700px">
            <div class="p-4" v-if="selectedTechOptiTrajet">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold text-xl">
                        <div>{{ selectedTechOptiTrajet.title }} - {{ selectedDateOptiTrajet | momentDate }}</div>
                        <div class="text-base font-light">Optimisation de la journée</div>
                    </h2>
                    <button @click="$modal.hide('modal-opti-trajets')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div v-if="loadingOptiTrajet" class="p-4">
                        <spinner :size="10" />
                    </div>
                    <div v-if="!loadingOptiTrajet && optiTrajets && optiTrajets.default && optiTrajets.new">
                        <div v-if="optiTrajets.default.cost_total <= optiTrajets.new.cost_total">
                            <div class="bg-green-100 text-green-800 p-4 font-bold">
                                <i class="fas fa-check mr-2"></i> Cette journée est optimisé au maximum
                            </div>
                            <button class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded mt-4">Fermer la fenêtre</button>
                        </div>
                        <div v-if="optiTrajets.default.cost_total > optiTrajets.new.cost_total">
                            <table class="w-full text-sm text-left text-gray-500">
                                <thead class="text-xs text-gray-700 uppercase bg-gray-50 border-b border-slate-300">
                                    <tr>
                                        <th class="px-1.5 py-2 bg-white border-b w-[150px]">
                                        </th>
                                        <th class="px-1.5 py-2 text-center bg-slate-100 w-[200px] border">
                                            <div>Au planning</div>
                                        </th>
                                        <th class="px-1.5 py-2 text-center bg-slate-100 border">
                                            <div>Optimisé</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="border-b border-slate-300">
                                        <td class="text-sm border px-1.5 py-2 text-gray-900 whitespace-nowrap bg-slate-100">Distance</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap">{{ optiTrajets.default.total_distance_km }} km</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap">{{ optiTrajets.new.total_distance_km }} km</td>
                                    </tr>
                                    <tr class="border-b border-slate-300">
                                        <td class="text-sm border px-1.5 py-2 text-gray-900 whitespace-nowrap bg-slate-100">Temps de trajet</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap">{{ changeMinutesFormat(optiTrajets.default.total_duration_min) }}</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap">{{ changeMinutesFormat(optiTrajets.new.total_duration_min) }}</td>
                                    </tr>
                                    <tr class="border-b border-slate-300">
                                        <td class="text-sm border px-1.5 py-2 text-gray-900 whitespace-nowrap bg-slate-100">Coût Salaire</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap">{{ optiTrajets.default.hour_mo }} €</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap">{{ optiTrajets.new.hour_mo }} €</td>
                                    </tr>
                                    <tr class="border-b border-slate-300">
                                        <td class="text-sm border px-1.5 py-2 text-gray-900 whitespace-nowrap bg-slate-100">Coût Essence</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap">{{ optiTrajets.default.fuel.cost }} €</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap">{{ optiTrajets.new.fuel.cost }} €</td>
                                    </tr>
                                    <tr class="border-b border-slate-300">
                                        <td class="text-sm border px-1.5 py-2 text-gray-900 whitespace-nowrap font-bold bg-slate-200">Coût Total</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap font-bold text-red-600 bg-red-100">{{ optiTrajets.default.cost_total }} €</td>
                                        <td class="text-sm border px-1.5 py-2 text-center text-gray-900 whitespace-nowrap font-bold text-green-600 bg-green-100">{{ optiTrajets.new.cost_total }} €</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="mt-5 text-center">
                                <div class="text-xl font-bold">Vous économisez {{ parseFloat(optiTrajets.default.cost_total - optiTrajets.new.cost_total).toFixed(2) }} €</div>
                                <button class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 rounded text-white my-5"><i class="fas fa-check mr-1"></i> Appliquer l'optimisation</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <!-- Modal opti days -->
        <modal name="modal-opti-days" height="auto" :scrollable="true" width="900px">
            <div class="p-4">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        Optimisation de la journée
                    </h2>
                    <button @click="$modal.hide('modal-opti-days')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div v-if="loadingOptiDays" class="p-4">
                        <spinner :size="10" />
                    </div>
                    <div v-if="!loadingOptiDays">
                        <div class="overflow-y-auto max-h-[400px]">
                            <table class="w-full text-sm text-left text-gray-500 border border-slate-300">
                                <thead class="text-xs text-gray-700 uppercase-300 border-b bg-slate-200">
                                    <tr>
                                        <th class="px-1.5 py-2 text-center">
                                            <div>Date</div>
                                        </th>
                                        <th class="px-0.5 py-2 text-center">
                                            <div>Adresse</div>
                                        </th>
                                        <th class="px-1.5 py-2 text-center">Tech</th>
                                        <th class="px-1.5 py-2 text-center">
                                            <div>Nb Inter</div>
                                        </th>
                                        <th class="px-1.5 py-2 text-center">
                                            <div>Distance</div>
                                        </th>
                                        <th class="px-1.5 py-2 text-center">
                                            <div>Temps</div>
                                        </th>
                                        <th class="px-1.5 py-2 text-center">
                                            <div>Coût total</div>
                                        </th>
                                        <th class="px-1.5 py-2 text-center">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="border-b border-slate-300" v-for="(optiDay, optiDayIndex) in optiDays" :key="'opti-day-' + optiDayIndex">
                                        <td class="text-sm px-1.5 py-1 text-center font-bold text-gray-900 whitespace-nowrap">{{ optiDay.date | momentDate }}</td>
                                        <td class="text-sm px-0.5 py-1 text-center font-light text-gray-900 whitespace-nowrap">{{ optiDay.intervention_precedente.cp + ' ' + optiDay.intervention_precedente.ville }}</td>
                                        <td class="text-sm px-1.5 py-1 text-center font-light text-gray-900 whitespace-nowrap">{{ optiDay.intervention_precedente.tech?.matricule }}</td>
                                        <td class="text-sm px-1.5 py-1 text-center font-light text-gray-900 whitespace-nowrap">{{ optiDay.nombre_interventions_jour }}</td>
                                        <td class="text-sm px-1.5 py-1 text-center font-light text-gray-900 whitespace-nowrap">{{ optiDay.distance }} km</td>
                                        <td class="text-sm px-1.5 py-1 text-center font-light text-gray-900 whitespace-nowrap">{{ convertDecimalToHours(optiDay.temps_trajet) }}</td>
                                        <td class="text-sm px-1.5 py-1 text-center font-bold text-gray-900 whitespace-nowrap">{{ optiDay.cout_total_trajet }} €</td>
                                        <td class="text-sm pl-3 py-1 text-center font-bold text-gray-900 whitespace-nowrap">
                                            <button @click="openOptiDaysTrajet(optiDay)" class="text-white rounded text-xs duration-200 font-bold px-4 py-2 bg-blue-500 hover:bg-blue-600"><i class="fas fa-clock"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal opti plan day -->
        <modal name="modal-opti-plan-day" height="auto" :scrollable="true" width="900px">
            <div class="p-4" v-if="selectedPlanDay">
                <div class="flex items-center justify-end mb-1 pb-1 border-b text-2xl">
                    <button @click="$modal.hide('modal-opti-plan-day')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="grid grid-cols-4 gap-4">
                        <div>
                            <div class="font-bold mb-2 pb-2 border-b">
                                {{ selectedPlanDay.date | momentDate }}
                            </div>
                            <div class="font-bold">Intervenant</div>
                            <div>{{ selectedPlanDay.intervention_precedente.tech.matricule }}</div>
                            <div>
                                Départ domicile tech:
                                <i v-if="selectedPlanDay.intervention_precedente.tech.depart_dom == 1" class="fas fa-check text-green-500"></i>
                                <i v-if="selectedPlanDay.intervention_precedente.tech.depart_dom == 0" class="fas fa-times text-red-500"></i>
                            </div>
                            <div>
                                Retour domicile tech:
                                <i v-if="selectedPlanDay.intervention_precedente.tech.retour_dom == 1" class="fas fa-check text-green-500"></i>
                                <i v-if="selectedPlanDay.intervention_precedente.tech.retour_dom == 0" class="fas fa-times text-red-500"></i>
                            </div>
                            <div>Temps travail accepté: <b>{{ convertDecimalToHours(selectedPlanDay.intervention_precedente.tech['temps' + getMomentDay(selectedPlanDay.date)]) }}</b></div>
                            <div>Temps supp accepté: <b>{{ convertDecimalToHours(selectedPlanDay.intervention_precedente.tech['temps_supp_' + getMomentDay(selectedPlanDay.date)]) }}</b></div>
                            <div>Pause: <b>{{ convertDecimalToHours(selectedPlanDay.intervention_precedente.tech['temps_pause_' + getMomentDay(selectedPlanDay.date)]) }}</b></div>
                            <div class="mt-20">
                                <table v-if="selectedDaySummary">
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Nb Km parcouru</td>
                                        <td class="p-1 text-right w-[150px] font-bold">{{ selectedDaySummary.nb_km_parcouru }} km</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Temps de trajet</td>
                                        <td class="p-1 text-right w-[150px] font-bold">{{ convertDecimalToHours(selectedDaySummary.temps_trajet) }}</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Temps d'intervention</td>
                                        <td class="p-1 text-right w-[150px] font-bold">{{ convertDecimalToHours(selectedDaySummary.temps_intervention) }}</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Temps global</td>
                                        <td class="p-1 text-right w-[150px] font-bold">{{ convertDecimalToHours(selectedDaySummary.temps_global) }}</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Cout du trajet</td>
                                        <td class="p-1 text-right w-[150px] font-bold">{{ selectedDaySummary.cout_trajet }} €</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Cout du technicien</td>
                                        <td class="p-1 text-right w-[150px] font-bold">{{ selectedDaySummary.cost_tech_total }} €</td>
                                    </tr>
                                    <tr class="border bg-slate-100">
                                        <td class="p-1 text-sm font-light">Tournée actuelle</td>
                                        <td class="p-1 text-right w-[150px] font-bold">{{ selectedDaySummary.cout_total }} €</td>
                                    </tr>
                                </table>
                                <div class="mt-2 space-y-2">
                                    <div><button class="px-4 py-2 text-xs bg-blue-500 hover:bg-blue-600 duration-200 rounded text-white">Enregistrer</button></div>
                                    <div><button class="px-4 py-2 text-xs bg-slate-500 hover:bg-slate-600 duration-200 rounded text-white">Cartographie</button></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <planning-day
                            :conf-planning="confPlanning"
                            :selected-data="selectedPlanDay"
                            :events="getPlanningOptiDay()"
                            />
                        </div>
                        <!-- <div>
                            <planning-day :conf-planning="confPlanning" />
                        </div> -->
                        <!-- <div>
                            <div class="mt-44">
                                <div class="font-bold mb-2">Gain possible: 0.00€</div>
                                <table>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Nb Km parcouru</td>
                                        <td class="p-1 text-right w-[150px] font-bold">40 km</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Temps de trajet</td>
                                        <td class="p-1 text-right w-[150px] font-bold">0h40</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Temps d'intervention</td>
                                        <td class="p-1 text-right w-[150px] font-bold">1h15</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Temps global</td>
                                        <td class="p-1 text-right w-[150px] font-bold">1h55</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Cout du trajet</td>
                                        <td class="p-1 text-right w-[150px] font-bold">40.00 €</td>
                                    </tr>
                                    <tr class="border">
                                        <td class="p-1 text-sm font-light">Cout du technicien</td>
                                        <td class="p-1 text-right w-[150px] font-bold">26.55 €</td>
                                    </tr>
                                    <tr class="border bg-slate-100">
                                        <td class="p-1 text-sm font-light">Tournée optimisée</td>
                                        <td class="p-1 text-right w-[150px] font-bold">66.55 €</td>
                                    </tr>
                                </table>
                                <div class="mt-2 space-y-2">
                                    <div><button class="px-4 py-2 text-xs bg-blue-500 hover:bg-blue-600 duration-200 rounded text-white">Enregistrer</button></div>
                                    <div><button class="px-4 py-2 text-xs bg-slate-500 hover:bg-slate-600 duration-200 rounded text-white">Cartographie</button></div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal edit employe -->
        <modal name="modal-event" height="auto" :scrollable="true" width="700px">
            <div>
                <div class="text-right px-4 py-2">
                    <button @click="$modal.hide('modal-event')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                
                <div v-if="selectedEvent">
                    <div class="grid grid-cols-3 border-y-2 border-slate-600 p-4">
                        <div class="text-xs col-span-2">
                            <div>
                                Client: <span class="font-bold">{{ selectedEvent.extendedProps.dossier.end_customer.nom + ' ' + selectedEvent.extendedProps.dossier.end_customer.prenom }}</span>
                            </div>
                            <div>
                                Téléphone: <span class="font-bold">{{ selectedEvent.extendedProps.dossier.end_customer.tel }}</span>
                            </div>
                            <div>
                                Adresse: <span class="font-bold">{{ selectedEvent.extendedProps.dossier.end_customer.adresse1 + ', ' + selectedEvent.extendedProps.dossier.end_customer.cp + ' ' + selectedEvent.extendedProps.dossier.end_customer.ville }}</span>
                            </div>
                            <div>
                                Produit: <span class="font-bold">{{ selectedEvent.extendedProps.dossier.end_customer.nom + ' ' + selectedEvent.extendedProps.dossier.end_customer.prenom }}</span>
                            </div>
                        </div>
                        <div class="text-xs">
                            <div>Trajet: 0 Min</div>
                            <div>Intervention: 15 min</div>
                            <div>Km ref: 0</div>
                            <div class="flex items-center gap-1.5">Km fact: <input class="border p-0.5 focus:outline-none w-[60px]" type="number" :label-inline="false" /></div>
                        </div>
                    </div>
                    <div class="p-4 border-b-2 border-slate-600">
                        <div class="text-center font-light text-sm">Détail de l'intervention</div>
                        <div class="font-bold text-center">ICI le détail de l'intervention</div>
                    </div>
                    <div class="p-4 border-b-2 border-slate-600">
                        <div class="text-xs">
                            <button class="px-2 py-0.5 bg-slate-200 hover:bg-slate-200 duration-200 rounded text-sm mr-2"><i class="fas fa-plus"></i></button> Pièces au prévisionnel
                        </div>
                        <table class="w-full mt-4">
                            <tbody>
                                <tr v-for="i in 4" :key="i">
                                    <td class="p-1 border"></td>
                                    <td class="p-1 border"></td>
                                    <td class="p-1 border"></td>
                                    <td class="p-1 border"></td>
                                    <td class="p-1 border"></td>
                                    <td class="p-1 border"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="p-4 border-b-2 border-slate-600">
                        <div class="grid grid-cols-2 gap-2">
                            <div>
                                <input-rule class="mb-2" type="datetime" label="Date préférentielle du RDV par le client" :label-inline="false" />
                                <input-rule class="mb-2" type="datetime" label="Rdv accepté par le client" :label-inline="false" />
                                <input-rule class="mb-2" type="time" label="Temps prévisionnel" :label-inline="false" />
                            </div>
                            <div>
                                <input-rule class="mb-2" type="number" label="Nombre de techniciens" :label-inline="false" />
                                <input-rule class="mb-2" type="vselect" label="Techniciens #1" :options="{values: techs, label: 'title', key: 'id'}" :label-inline="false" />
                            </div>
                        </div>
                    </div>
                    <div class="p-4 flex justify-between gap-3">
                        <div>
                            <button :disabled="loadingRappel" @click="toggleRappel" class="px-4 py-2 rounded duration-200 text-xs" :class="selectedEvent.extendedProps.intervention.a_rappeler == 0 ? 'bg-slate-200 hover:bg-slate-300' : 'text-white bg-orange-800 hover:bg-orange-900'">
                                <spinner v-if="loadingRappel" :size="10" />
                                <template v-if="!loadingRappel">
                                    <template v-if="!selectedEvent.extendedProps.intervention.a_rappeler">A Rappeler</template>
                                    <template v-if="selectedEvent.extendedProps.intervention.a_rappeler"> <i class="fas fa-exclamation-triangle mr-2"></i>Attente de rappel</template>
                                </template>
                            </button>
                        </div>
                        <div><button @click="displayModalMemo" class="px-4 py-2 rounded bg-slate-200 hover:bg-slate-300 duration-200 text-xs">Mémo</button></div>
                        <div><button class="px-4 py-2 rounded bg-slate-200 hover:bg-slate-300 duration-200 text-xs">Edition</button></div>
                        <div>
                            <button :disabled="loadingBlocage" @click="toggleBlocage" class="px-4 py-2 rounded duration-200 text-xs" :class="selectedEvent.extendedProps.intervention.heure_modifiable ? 'bg-slate-200 hover:bg-slate-300' : 'bg-red-200 hover:bg-red-300'">
                                <spinner v-if="loadingBlocage" :size="10" />
                                <template v-if="!loadingBlocage">
                                    <template v-if="selectedEvent.extendedProps.intervention.heure_modifiable">Blocage</template>
                                    <template v-if="!selectedEvent.extendedProps.intervention.heure_modifiable">Bloqué</template>
                                </template>
                            </button>
                        </div>
                        <div>
                            <button :disabled="loadingRemoveEvent" @click="removeEvent" class="px-4 py-2 rounded bg-orange-200 hover:bg-orange-300 duration-200 text-xs">
                                <spinner v-if="loadingRemoveEvent" :size="10" />
                                <template v-if="!loadingRemoveEvent">Déplannifier</template>
                            </button>
                        </div>
                        <div><button class="px-4 py-2 rounded bg-green-200 hover:bg-green-300 duration-200 text-xs">Enregistrer</button></div>
                    </div>
                    <!-- {{ selectedEvent }} -->
                </div>
            </div>
        </modal>
        <!-- Modal memos -->
        <modal name="modal-memo" height="auto" :scrollable="true" width="70%">
            <div>
                <div class="text-right px-4 py-2">
                    <button @click="$modal.hide('modal-memo')"><i class="fas fa-times text-red-500 text-xl"></i></button>
                </div>
                
                <div class="p-4 pt-0">
                    <div class="grid grid-cols-2 gap-6">
                        <div>
                            <div class="text-lg font-bold">Mémo</div>
                            <div class="text-xs font-light mb-2 mt-0.5">Ajouter une note</div>
                            <div class="max-h-[400px] overflow-y-auto">
                                <div v-for="(memo, memoIndex) in memos" :key="'memo-msg-' + memoIndex" class="mb-3">
                                    <div class="flex items-center gap-1 text-xs font-light mb-0.5">
                                        <div>{{ memo.tech?.matricule }}</div>
                                        <div>-</div>
                                        <div>{{ memo.date | momentDate }}</div>
                                    </div>
                                    <div class="bg-slate-100 p-2 rounded">
                                        <div class="mb-1 text-sm" v-html="memo.memo"></div>
                                    </div>
                                </div>
                            </div>
                            <input-rule v-model="inpNewMemo" type="richtext" :label-inline="false" />
                            <button :disabled="loadingAddMemo" @click="sendMemo" class="mt-2 px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-xs">
                                <spinner v-if="loadingAddMemo" :size="10" />
                                <template v-if="!loadingAddMemo">
                                    <i class="fas fa-plus mr-1"></i> Ajouter
                                </template>
                            </button>
                        </div>
                        <div>
                            <div class="bg-slate-700 text-white p-4 rounded">
                                <div class="text-lg font-bold">Info fiche de route</div>
                                <div class="text-xs font-light mb-2 mt-0.5">Accessoires et compléments à prendre</div>
                                <input-rule class="text-slate-800" v-model="inpFicheRoute" type="richtext" :label-inline="false" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <app-template>
            <div class="fixed left-0 top-0 bottom-0 w-[300px] bg-slate-800 p-2">
                <div class="bg-white mt-4 text-xs text-center py-1">
                    <date-picker
                    v-model="datepickerDate"
                    valueType="format"
                    :open="true"
                    :inline="true"
                    :lang="lang"
                    />
                </div>
                
                <div class="bg-white mt-4 text-xs">
                    <div
                    class="border-b p-1.5 px-2 cursor-pointer duration-200"
                    :class="selectedAvancement == 'a_rappeler' ? 'bg-orange-600 text-white' : 'text-orange-600 font-bold hover:bg-orange-100'"
                    @click="selectedAvancement = 'a_rappeler'"
                    >
                        <div class="flex items-center gap-2 justify-between">
                            <div>
                                <i class="fas fa-exclamation-triangle mr-1"></i> {{ 'A rappeler' }}
                            </div>
                            <div class="bg-slate-200 rounded-full px-2 text-slate-800">{{ rappelEvents.length }}</div>
                        </div>
                    </div>
                    <div
                    class="border-b p-1.5 px-2 cursor-pointer duration-200"
                    :class="selectedAvancement == 'planning' ? 'bg-blue-600 text-white' : 'hover:bg-slate-100'"
                    @click="selectedAvancement = 'planning'"
                    >
                        <div class="flex items-center gap-2 justify-between">
                            {{ 'IAD - Au planning' }}
                            <div class="bg-slate-200 rounded-full px-2 text-slate-800">{{ planningEvents.length }}</div>
                        </div>
                    </div>
                    <div
                    class="border-b p-1.5 px-2 cursor-pointer duration-200"
                    :class="selectedAvancement == avancementIndex ? 'bg-blue-600 text-white' : 'hover:bg-slate-100'"
                    @click="selectedAvancement = avancementIndex"
                    v-for="(avancement, avancementIndex) in avancements"
                    :key="'avancement-' + avancementIndex"
                    >
                    <div class="flex items-center gap-2 justify-between">
                        {{ avancementIndex }}
                        <div class="bg-slate-200 rounded-full px-2 text-slate-800">{{ avancement.length }}</div>
                    </div>
                </div>
            </div>
            
            <div class="bg-white mt-4 p-2 text-xs">
                <div v-if="selectedAvancement">
                    <template v-if="selectedAvancement == 'planning'">
                        <div class="max-h-[200px] overflow-y-auto">
                            <div v-if="!planningEvents || planningEvents.length <= 0">
                                Aucun événement
                            </div>
                            <div
                            @click="menuSelectDossier(dossier)"
                            v-for="(dossier, dossierIndex) in planningEvents"
                            :key="'dossier-' + dossierIndex"
                            class="border-b py-1 cursor-pointer hover:bg-slate-100"
                            >
                                <div class="font-bold text-blue-600">
                                    {{ dossier.dossier.end_customer.nom }}
                                    {{ dossier.dossier.end_customer.prenom }}
                                </div>
                                <div>
                                    <i class="fas fa-location-dot mr-0.5"></i>
                                    {{ dossier.dossier.end_customer.cp }}
                                    {{ dossier.dossier.end_customer.ville }}
                                </div>
                                <div>
                                    <i class="fas fa-circle-info mr-0.5"></i>
                                    {{ dossier.dossier.device['Marque'] }}
                                    {{ dossier.dossier.device['Modele'] }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="selectedAvancement == 'a_rappeler'">
                        <div class="max-h-[200px] overflow-y-auto">
                            <div v-if="!rappelEvents || rappelEvents.length <= 0">
                                Aucun événement
                            </div>
                            <div
                            @click="menuSelectDossier(dossier)"
                            v-for="(dossier, dossierIndex) in rappelEvents"
                            :key="'dossier-' + dossierIndex"
                            class="border-b py-1 cursor-pointer hover:bg-slate-100"
                            >
                                <div class="font-bold text-blue-600">
                                    {{ dossier.dossier.end_customer.nom }}
                                    {{ dossier.dossier.end_customer.prenom }}
                                </div>
                                <div>
                                    <i class="fas fa-location-dot mr-0.5"></i>
                                    {{ dossier.dossier.end_customer.cp }}
                                    {{ dossier.dossier.end_customer.ville }}
                                </div>
                                <div>
                                    <i class="fas fa-circle-info mr-0.5"></i>
                                    {{ dossier.dossier.device['Marque'] }}
                                    {{ dossier.dossier.device['Modele'] }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="flex gap-1">
                            <input type="text" v-model="filterSearchEvent" placeholder="Rechercher.." :label-inline="false" class="mb-1 text-xs border-b w-full py-1 focus:outline-none" />
                            <button v-if="filterSearchEvent" @click="filterSearchEvent = null"><i class="fas fa-times text-red-500"></i></button>
                        </div>
                        <div class="max-h-[200px] overflow-y-auto event-item">
                            <div
                            v-for="(dossier, dossierIndex) in searchListEvent"
                            :key="'dossier-' + dossierIndex"
                            class="border-b py-1 cursor-pointer hover:bg-slate-100"
                            :data-event="JSON.stringify({
                                title: dossier.id_dossiersav,
                                duration: convertMinutesToTime(dossier.categ_sta?.distri_categ?.temps_mo_previ) || '01:00:00',
                                id_dossier: dossier.id_dossiersav,
                                dossier: dossier
                            })"
                            >
                            <div class="flex items-center gap-2 justify-between">
                                <div>
                                    <div class="font-bold text-blue-600">
                                        {{ dossier.end_customer.nom }}
                                        {{ dossier.end_customer.prenom }}
                                    </div>
                                    <div>
                                        <i class="fas fa-location-dot mr-0.5"></i>
                                        {{ dossier.end_customer.cp }}
                                        {{ dossier.end_customer.ville }}
                                    </div>
                                    <div>
                                        <i class="fas fa-circle-info mr-0.5"></i>
                                        {{ dossier.device['Marque'] }}
                                        {{ dossier.device['Modele'] }}
                                    </div>
                                </div>
                                <button class="px-2.5 py-2.5 bg-slate-200 hover:bg-slate-300 duration-200 rounded" style="font-size: 16px" @click="openOptiDays(dossier)"><i class="fas fa-magnifying-glass-location"></i></button>
                            </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="fixed left-0 top-0 bottom-0 left-[300px] right-0 overflow-y-auto">
            <div class="bg-white p-2 rounded rounded-tl-none border shadow-sm mb-16">
                <div class="py-10" v-if="loadingInit">
                    <div class="flex justify-center">
                        <spinner :size="12" />
                    </div>
                    <div class="mt-4 text-sm font-extralight text-center">{{ $tt('Chargement des données') }}..</div>
                </div>
                <div v-if="displayCalendar">
                    <div class="flex items-end justify-end gap-3 mb-2">
                        <button @click="subDay" class="text-xs bg-slate-100 py-1 px-2 rounded"><i class="fas fa-chevron-left"></i></button>
                        <button @click="setToday" class="text-xs bg-slate-100 py-1 px-2 rounded"><i class="fas fa-calendar"></i></button>
                        <button @click="addDay" class="text-xs bg-slate-100 py-1 px-2 rounded"><i class="fas fa-chevron-right"></i></button>
                    </div>

                    <div class="calendar-container">
                        <FullCalendar
                        ref="calendar"
                        :options="calendarOptions"
                        />
                    </div>

                    <div class="mt-10">
                        <form-planning />
                    </div>
                </div>
            </div>
        </div>
    </app-template>
</div>
</template>


<script>
import FullCalendar from '@fullcalendar/vue'; // Pour Vue 2
// import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import frLocale from '@fullcalendar/core/locales/fr';
import scrollgrid from '@fullcalendar/scrollgrid';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import fr from 'vue2-datepicker/locale/fr';

import ablyService from '@/services/ably.service.js';

import moment from 'moment'

import FormPlanning from './Components/FormPlanning.vue';
import PlanningDay from './Components/PlanningDay.vue';

export default {
    components: {
        FullCalendar,
        DatePicker,

        FormPlanning,
        PlanningDay
    },
    watch: {
        datepickerDate(value) {
            if (this.$refs.calendar) {
                const calendarApi = this.$refs.calendar.getApi();
                calendarApi.gotoDate(value);
                this.initOptiButtons()
            }
        },
        selectedAvancement() {
            this.$nextTick(() => {
                this.initializeDraggableEvents()
            })
        },
        inpFicheRoute() {
            this.editFicheRoute()
        }
    },
    data() {
        return {
            confPlanning: null,
            displayCalendar: false,
            calendarOptions: {
                locales: [ frLocale ],
                locale: 'fr',
                timeZone: 'Europe/Paris',
                headerToolbar: false,
                plugins: [resourceTimeGridPlugin, resourceTimelinePlugin, interactionPlugin, scrollgrid],
                resources: [],
                initialView: 'resourceTimeGridDay',
                views: {
                    resourceTimeGridDay: {
                        slotDuration: '00:15:00', // Intervalles de 15 minutes
                        slotLabelInterval: '00:15:00',
                        slotMinTime: '08:00:00',
                        slotMaxTime: '18:00:00',
                        allDaySlot: false,
                    },
                },
                contentHeight: 'auto', // Ajuste la hauteur dynamiquement
                dayMaxEvents: false, // Affiche tous les événements sans limitation
                dayMinWidth: 100,
                nowIndicator: true,
                dateClick: this.dateClick,
                eventDrop: this.onEventDrop,
                eventReceive: this.onEventReceive,
                eventResize: this.onEventResize,
                eventClick: this.onEventClient,
                eventContent: this.buildEventContent,
                editable: true, // Activer la modification par drag and drop
                events: [],
                schedulerLicenseKey: '0064802622-fcs-1734624337',
                datesSet: this.onDatasSet,
                eventAllow: this.disablePastEvents,
                slotLabelFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: false
                },
            },
            
            datepickerDate: null,
            lang: fr,
            
            techs: [],
            
            avancements: [],
            planningEvents: [],
            selectedAvancement: 'planning',
            filterSearchEvent: null,

            memos: [],
            inpNewMemo: null,
            inpFicheRoute: null,
            timerFicheRoute: null,
            loadingAddMemo: false,
            loadingBlocage: false,
            loadingRappel: false,

            selectedEvent: null,

            loadingRemoveEvent: false,

            loadingInit: false,

            optiDays: [],
            loadingOptiDays: false,
            selectedDossierOptiDay: null,

            optiTrajets: [],
            selectedTechOptiTrajet: null,
            selectedDateOptiTrajet: null,
            loadingOptiTrajet: false,
            optiDaysData: null,
            selectedPlanDay: null,
            optiDaysSummaries: null,
            selectedDaySummary: null
        };
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    computed: {
        searchListEvent() {
            let endData = []
            if (this.avancements[this.selectedAvancement]) {
                endData = JSON.parse(JSON.stringify(this.avancements[this.selectedAvancement]))
            }
            let endIds = []
            if (this.filterSearchEvent) {
                endIds = endIds.concat(endData.filter((line) => line.end_customer.nom.toLowerCase().startsWith(this.filterSearchEvent.toLowerCase().trim())).map((item) => { return item.id_dossiersav }))
                endIds = endIds.concat(endData.filter((line) => line.end_customer.prenom.toLowerCase().startsWith(this.filterSearchEvent.toLowerCase().trim())).map((item) => { return item.id_dossiersav }))
                endIds = endIds.concat(endData.filter((line) => line.end_customer.ville.toLowerCase().startsWith(this.filterSearchEvent.toLowerCase().trim())).map((item) => { return item.id_dossiersav }))
                endIds = endIds.concat(endData.filter((line) => line.end_customer.cp.toLowerCase().startsWith(this.filterSearchEvent.toLowerCase().trim())).map((item) => { return item.id_dossiersav }))
                endIds = endIds.concat(endData.filter((line) => line.device['Marque'].toLowerCase().startsWith(this.filterSearchEvent.toLowerCase().trim())).map((item) => { return item.id_dossiersav }))
                endIds = endIds.concat(endData.filter((line) => line.device['Modele'].toLowerCase().startsWith(this.filterSearchEvent.toLowerCase().trim())).map((item) => { return item.id_dossiersav }))
            }
            
            if (endIds.length > 0) {
                return endData.filter((item) => endIds.includes(item.id_dossiersav))
            }
            return endData
        },
        rappelEvents() {
            let endData = JSON.parse(JSON.stringify(this.planningEvents))
            endData = endData.filter((item) => item.intervention.a_rappeler == 1)

            return endData
        }
    },
    methods: {
        /* --------
        Init
        ---------*/
        async init() {
            this.loadingInit = true
            // Set default date
            this.datepickerDate = moment().format('YYYY-MM-DD');
            
            await this.getConf()

            // Display calendar set
            this.displayCalendar = true
            this.$nextTick(() => {
                this.highlightHours();
                this.initOptiButtons()
            })

            this.loadingInit = false
        },

        async getConf() {
            this.confPlanning = await this.$request.send('planning.get-conf')
            this.calendarOptions.views.resourceTimeGridDay.slotDuration = this.confPlanning.interval
            this.calendarOptions.views.resourceTimeGridDay.slotLabelInterval = this.confPlanning.interval
            this.calendarOptions.views.resourceTimeGridDay.slotMinTime = this.confPlanning.min_time
            this.calendarOptions.views.resourceTimeGridDay.slotMaxTime = this.confPlanning.max_time
        },
        
        highlightHours() {
            const labels = this.$refs.calendar.$el.querySelectorAll('.fc-timegrid-slot-label-cushion');
            
            labels.forEach((label) => {
                const text = label.textContent || label.innerText;
                
                // Vérifie si l'heure est une heure principale (se termine par ":00")
                if (text.endsWith(':00')) {
                    label.classList.add('hour-label'); // Ajoute une classe personnalisée
                    label.innerHTML = text.split(':')[0] + 'h'
                } else {
                    label.classList.add('minute-label'); // Ajoute une classe pour les minutes
                    label.innerHTML = text.split(':')[1]
                }
            });
        },
        
        initializeDraggableEvents() {
            const eventItems = document.querySelectorAll('.event-item div');

            eventItems.forEach((el) => {
                const eventData = JSON.parse(el.getAttribute('data-event'));
                new Draggable(el, {eventData});
            });
        },

        // Can't edit past event
        disablePastEvents(dropInfo, draggedEvent) {
            if (!draggedEvent.start) {
                return true
            }
            
            if (draggedEvent._def.extendedProps.intervention && draggedEvent._def.extendedProps.intervention.heure_modifiable == 0) {
                return false
            }
            
            const now = new Date();
            return draggedEvent.start >= now;
        },

        initOptiButtons() {
            document.querySelectorAll('.fc-col-header-cell').forEach((cell) => {
                // Vérifiez si le bouton existe déjà pour éviter les doublons
                if (!cell.querySelector('.header-btn-opti')) {
                    const button = document.createElement('button');
                    button.textContent = 'Opti';
                    button.className = 'header-btn-opti';
                    button.style.marginLeft = '5px';
                    button.onclick = this.openOptiTrajet
                    cell.prepend(button);
                }
            });
        },
        
        /* -----------
        Get data
        ------------*/
        async getTechs() {
            this.techs = await this.$request.send('planning.get-techs')
            this.calendarOptions.resources = this.techs
        },
        
        // async getEvents() {
        //     // Events from database
        //     let events = await this.$request.send('planning.get-events')
        //     events = events.map((item) => ({
        //         ...item,
        //         start: item.date_debut + 'T' + item.heure_debut,
        //         title: item.id,
        //         resourceId: item.id_tech,
        //     }))
        //     this.calendarOptions.events = events
        // },

        setPlanning(data) {
            let events = data.map((item) => ({
                ...item,
                start: item.date_debut + 'T' + item.heure_debut,
                end: item.date_fin + 'T' + item.heure_fin,
                title: item.id_dossier,
                resourceId: item.id_tech,
                id: item.id,
                color: '#4781de',
                heure_modifiable: item.heure_modifiable
            }))
            this.planningEvents = events
            this.calendarOptions.events = this.planningEvents
        },

        async refreshAvancements() {
            let response = await this.$request.send('planning.get-avancements')
            this.avancements = response.waiting
        },
        
        async getAvancements() {
            let response = await this.$request.send('planning.get-avancements')
            this.avancements = response.waiting
            this.setPlanning(response.planning)
        },

        buildEventContent(arg) {            
            let customContent = document.createElement('div');
            if (arg.event.extendedProps.dossier?.end_customer) {
                customContent.innerHTML = `
                    <div>
                        <div class='flex items-center gap-2 bg-blue-800/90 border border-blue-800' style='font-size: 10px;'>
                            ${arg.event.extendedProps.intervention?.heure_modifiable === 0 ? "<span class='p-1'><i class='fas fa-lock'></i></span>" : ""}
                            ${arg.event.extendedProps.intervention?.a_rappeler === 1 ? "<span class='p-1'><i class='fas fa-phone'></i></span>" : ""}
                        </div>
                        <small class='font-bold'>${arg.event.extendedProps.dossier?.end_customer?.nom + ' ' + arg.event.extendedProps.dossier?.end_customer?.prenom}</small>
                        <div style='font-size: 10px'>${arg.event.extendedProps.dossier?.end_customer?.cp + ' ' + arg.event.extendedProps.dossier?.end_customer?.ville}</div>
                    </div>
                `;
            }
            return { domNodes: [customContent] };
        },

        setToday() {
            this.datepickerDate = moment().format('YYYY-MM-DD')
        },
        addDay() {            
            this.datepickerDate = moment(this.datepickerDate).add(1, 'days').format('YYYY-MM-DD')
        },
        subDay() {            
            this.datepickerDate = moment(this.datepickerDate).subtract(1, 'days').format('YYYY-MM-DD')
        },

        convertMinutesToTime(minutes) {
            if (!minutes) {
                return null
            }
            // Convertir les minutes en secondes
            const seconds = minutes * 60;

            // Utiliser moment.js pour formater
            const formattedTime = moment.utc(seconds * 1000).format('HH:mm:ss');

            return formattedTime;
        },
        
        /* --------
        Events
        ---------*/
        // move event on calendar
        async onEventDrop(info) {
            await this.$request.send('planning.edit-event', {
                line_id: info.event.id,
                start: info.event.start.toISOString(),
                end: info.event.end.toISOString(),
                id_tech: info.newResource ? info.newResource.id : null
            })
        },

        async onEventResize(info) {
            await this.$request.send('planning.edit-event', {
                line_id: info.event.id,
                end: info.event.end.toISOString(),
            })
        },

        // event drop on calendar from external list
        async onEventReceive(info) {
            const now = new Date();
            if (info.event.start < now) {
                this.$toast.error('Vous ne pouvez pas placer un élément dans le passé')
                return
            }
      
            await this.$request.send('planning.add-event', {
                id_dossier: info.event._def.extendedProps.id_dossier,
                id_tech: info.event.getResources()[0]._resource.id,
                start: info.event.start.toISOString(),
                end: info.event.end.toISOString(),
                type: 'intervention'
            })
        },

        onEventClient(info) {
            this.selectedEvent = info.event
            this.$modal.show('modal-event')
        },

        /* --------
        Modal event
        ---------*/

        async removeEvent() {
            this.loadingRemoveEvent = true
            let response = await this.$request.send('planning.remove-event', {
                line_id: this.selectedEvent.id
            })
            if (response == 1) {
                // await this.getAvancements()
                this.$modal.hide('modal-event')
            }
            this.loadingRemoveEvent = false
        },
        
        /* --------
        Menu
        ---------*/
        
        menuSelectDossier(dossier) {
            this.datepickerDate = dossier.date_debut
            this.calendarOptions.events.forEach((item) => {item.color = '#4781de', item.eventTextColor = '#fff'})
            let event = this.calendarOptions.events.find((item) => item.id == dossier.id)
            if (event) {
                event.color = '#afb50b'
            }
        },

        /* --------
        Memo
        ---------*/

        async getMemos() {
            this.memos = await this.$request.send('planning.get-memos', {
                id_dossier: this.selectedEvent._def.extendedProps.id_dossier
            })
            this.$forceUpdate()
        },

        displayModalMemo() { 
            this.inpFicheRoute = this.selectedEvent._def.extendedProps.intervention.fiche_route
            this.$modal.show('modal-memo')            
            this.getMemos()
        },

        async sendMemo() {
            this.loadingAddMemo = true
            await this.$request.send('planning.add-memo', {
                memo: this.inpNewMemo,
                id_dossier: this.selectedEvent._def.extendedProps.id_dossier
            })
            this.inpNewMemo = null
            await this.getMemos()
            this.loadingAddMemo = false
        },

        async editFicheRoute() {
            if (this.selectedEvent) {
                clearTimeout(this.timerFicheRoute)
                this.timerFicheRoute = setTimeout(async () => {
                    this.$request.send('planning.edit-fiche-route', {
                        id_intervention: this.selectedEvent._def.extendedProps.id_intervention,
                        message: this.inpFicheRoute
                    })
                }, 600)
            }
        },

        async toggleBlocage() {
            let block = 0
            
            if (this.selectedEvent._def.extendedProps.intervention.heure_modifiable) {
                block = 0
            } else {
                block = 1
            }
            
            this.loadingBlocage = true
            let response = await this.$request.send('planning.block-event', {
                id_intervention: this.selectedEvent._def.extendedProps.id_intervention,
                block: block
            })
            this.loadingBlocage = false
            
            this.selectedEvent._def.extendedProps.intervention.heure_modifiable = parseInt(response)
        },

        async toggleRappel() {
            let rappel = 0
            
            if (this.selectedEvent._def.extendedProps.intervention.a_rappeler) {
                rappel = 0
            } else {
                rappel = 1
            }
            
            this.loadingRappel = true
            let response = await this.$request.send('planning.a-rappeler-event', {
                id_intervention: this.selectedEvent._def.extendedProps.id_intervention,
                rappel: rappel
            })
            this.loadingRappel = false
            
            this.selectedEvent._def.extendedProps.intervention.a_rappeler = parseInt(response)
        },

        /* --------
        Opti
        ---------*/

        changeMinutesFormat(minutes) {
            // Calculer les heures entières et les minutes restantes
            const hours = Math.floor(minutes / 60); // Convertir en heures entières
            const remainingMinutes = Math.round(minutes % 60); // Minutes restantes

            // Formatage pour s'assurer que les minutes sont toujours sur 2 chiffres
            const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

            return `${hours}h${formattedMinutes}`;
        },

        getPreviousInterAdresse(interventions) {
            // Trouver l'index de l'intervention avec id_planning == 0
            const index = interventions.findIndex(intervention => intervention.id_planning === 0);

            // Vérifier si un index valide est trouvé et s'il y a une intervention avant
            if (index > 0) {
                let inter = interventions[index - 1]
                return '<div class="font-size:11px">' + inter.adresse1 + '</div><div style="font-size: 10px">' + inter.cp + ' ' + inter.ville + '</div>'
            }

            // Retourner null s'il n'y a pas d'intervention précédente
            return null;
        },

        async openOptiDays(dossier) {
            this.selectedDossierOptiDay = dossier

            this.$modal.show('modal-opti-days')

            this.loadingOptiDays = true
            let response = await this.$request.send('opti.nearest-routes2', {
                id_dossier: 152763
            })

            
            this.optiDaysData = response
            this.optiDays = response.trajets
            this.optiDaysSummaries = response.summaries
            this.loadingOptiDays = false
        },

        async openOptiDaysTrajet(optiDay) {
            this.selectedPlanDay = optiDay
            this.selectedDaySummary = this.optiDaysSummaries.find((item) => item.date_id_tech == this.selectedPlanDay.date + '_' + this.selectedPlanDay.intervention_precedente.tech.id)
            this.$modal.show('modal-opti-plan-day')
        },

        getPlanningOptiDay() {
            let selectedPlanning = []
            if (this.optiDaysData && this.selectedPlanDay) {
                this.optiDaysData.plannings.forEach((item) => {
                    if (item[1] && item[1].tech?.id == this.selectedPlanDay.intervention_precedente.tech?.id && item[1].date_debut == this.selectedPlanDay.date) {
                        selectedPlanning = item
                    }
                })
            }

            return selectedPlanning
            // <div>{{ selectedPlanDay.date }}</div>
            //         <div>{{ selectedPlanDay.intervention_precedente.tech.id }}</div>
            //         {{ optiDaysData.plannings }}
        },

        async planEventOptiDay(opti) {            
            const index = opti.planning.findIndex(intervention => intervention.id_planning === 0);
            let lastEvent = opti.planning[index - 1]
            let lastPlanningEvent = this.planningEvents.find((item) => item.id == lastEvent.id_planning)            
            
            await this.$request.send('planning.add-event', {
                id_dossier: this.selectedDossierOptiDay.id_dossiersav,
                id_tech: opti.technicien.id,
                start: lastPlanningEvent.date_debut + 'T' + lastPlanningEvent.heure_debut,
                end: moment(lastPlanningEvent.date_debut + 'T' + lastPlanningEvent.heure_debut).add('h', 1).toISOString(),
                type: 'intervention'
            })
            this.$modal.hide('modal-opti-days')
        },

        async openOptiTrajet(data) {
            let date = data.target.parentElement.getAttribute('data-date')
            let techId = data.target.parentElement.getAttribute('data-resource-id')            

            this.selectedTechOptiTrajet = this.techs.find((item) => item.id == techId)
            this.selectedDateOptiTrajet = date
            
            this.$modal.show('modal-opti-trajets')
            this.loadingOptiTrajet = true
            let response = await this.$request.post('opti.plan-day', {
                id_tech: techId,
                date: date
            })
            if (response.data.posts.error) {
                this.$modal.hide('modal-opti-trajets')
            } else {
                this.optiTrajets = response.data.posts.post
            }
            this.loadingOptiTrajet = false
        },

        convertDecimalToHours(decimal) {
            if (decimal) {
                const totalMinutes = Math.floor(decimal * 60); // Convertit en minutes
                const hours = Math.floor(totalMinutes / 60); // Nombre d'heures
                const minutes = totalMinutes % 60; // Minutes restantes
    
                return `${hours}h${minutes.toString().padStart(2, '0')}`; // Retourne sous forme "0hXX"
            }
            return '00h00'
        },

        getMomentDay(date) {
            let dayNb = moment(date).day()
            let days = {
                0: 'dimanche',
                1: 'lundi',
                2: 'mardi',
                3: 'mercredi',
                4: 'jeudi',
                5: 'vendredi',
                6: 'samedi',
            }
            return days[dayNb]
        }
    },
    async mounted() {
        this.loadingInit = true
        await this.getTechs()
        this.getAvancements()
        this.init()

        // Init Ably to refresh planning
        const ably = ablyService.init();
        this.channel = ably.channels.get('refresh-planning');
        this.channel.subscribe((message) => {
            if (message.data.type == 'planning') {
                this.setPlanning(message.data.data)
            }
            if (message.data.refresh_avancements == 1) {
                this.refreshAvancements()
            }
        });
    }
};
</script>

<style>
/* CALENDAR */

/* Header */
.fc-col-header-cell-cushion {
    font-size: 14px;
}

/* Styliser les heures */
.hour-label {
    font-size: 0.9em !important;
    font-weight: bold;
}

/* Styliser les minutes */
.minute-label {
    font-size: 0.7em !important;
    font-weight: normal;
    color: #888 !important; /* Texte plus clair pour les minutes */
}

/* DATEPICKER */
.mx-datepicker-main {
    border: 0px !important;
}
.mx-datepicker-popup {
    margin-left: 10px;
    padding: 0px !important;
}
.mx-calendar-panel-date {
    max-width: 340px !important;
}
.mx-calendar {
    padding: 2px !important;
}
.mx-calendar-header {
    height: 20px;
    line-height: 20px;
}

.vs__dropdown-menu {
    height: 120px;
}

/* Opti button */
.header-btn-opti {
    font-weight: 400;
    background-color: #f0f0f0;
    font-size: 12px;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.header-btn-opti:hover {
    animation-duration: 200ms;
    background-color: #e6e6e6;
}

</style>