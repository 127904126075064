import * as Ably from 'ably';

let ably;

export default {
    init() {
        if (!ably) {
            ably = new Ably.Realtime({ key: 'UBDqlg.En9MUg:9mExP1lI_EPY0ajLXSH7EpqN3mmqqpMLMzwypdsCk4A' });
        }
        return ably;
    },
    getInstance() {
        if (!ably) {
            throw new Error('Ably has not been initialized. Call init() first.');
        }
        return ably;
    },
    closeConnection() {
        if (ably) {
            ably.close(); // Ferme toutes les connexions
            ably = null; // Réinitialise l'instance
        }
    }
};
