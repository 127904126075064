<template>
    <div>
        <app-template>
            <div class="bg-white p-2 rounded rounded-tl-none border shadow-sm mb-16">
                <div class="p-10">
                    <div class="text-right">
                        <button @click="$modal.hide('modal-group-perm')"><i class="fas fa-times text-red-500"></i></button>
                    </div>
                    <div>
                        <div class="grid grid-cols-3 gap-4">
                            <div>
                                <div class="bg-slate-100 hover:bg-slate-200 duration-200 rounded px-4 py-2 text-center cursor-pointer" @click="selectedGroupPerm = {}">
                                    <i class="fas fa-plus-circle mr-1"></i> Créer une fonction
                                </div>
                                <div class="border-b py-2 cursor-pointer flex items-center gap-3" @click="selectedGroupPerm = perm" v-for="(perm, permIndex) in groupPerms" :key="'perm-modal-' + permIndex">
                                    <div class="w-[10px] h-[10px] rounded-full" :class="selectedGroupPerm?.id == perm.id ? 'bg-green-500' : 'bg-slate-100'"></div>
                                    {{ perm.nom }}
                                </div>
                            </div>
                            <div class="col-span-2">
                                <h2 class="font-bold text-xl border-b pb-3 mb-3">
                                    <template v-if="!selectedGroupPerm?.id">Ajouter une fonction</template>
                                    <template v-if="selectedGroupPerm?.id">Modifier une fonction</template>
                                </h2>
                                <div class="space-y-4">
                                    <input-rule type="text" v-model="selectedGroupPerm.nom" :label-inline="false" label="Nom de la fonction" />
                                    <div class="grid grid-cols-2 gap-6">
                                        <div v-for="(field, key) in droitFields" :key="key" class="flex items-center">
                                            <label class="inline-flex items-center">
                                                <input
                                                :label-inline="false"
                                                type="checkbox"
                                                v-model="selectedGroupPerm[key]"
                                                :id="key"
                                                class="form-checkbox h-4 w-4 text-blue-600"
                                                :true-value="1"
                                                :false-value="0"
                                                />
                                                <span class="ml-2 text-gray-700">{{ field.label }}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        <button @click="editGroupPerm" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm">Enregistrer</button>
                                        <button v-if="selectedGroupPerm.id" @click="deleteGroupPerm" class="px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 text-white rounded text-sm">Supprimer le groupe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            groupPerms: [],
            selectedGroupPerm: {},

            droitFields: {
                technique: { label: "Technique" },
                comptabilite: { label: "Comptabilité" },
                fact_garantie: { label: "Fact Garantie" },
                administration: { label: "Administration" },
                logistique: { label: "Logistique" },
                planning: { label: "Planning" },
                bdc: { label: "BDC" },
                previ_pieces: { label: "Prévi. pièce(s)" },
                stat: { label: "Stat" },
                commande_pf: { label: "Commande_pf" },
                avoir: { label: "Avoir" },
                sms: { label: "SMS" },
                dsp: { label: "DSP" },
                iad: { label: "Intervention à domicile" },
            },
        }
    },
    methods: {
        async getGroupPerms() {
            this.groupPerms = await this.$request.send('manage-tech.get-group-perms')
        },

        async editGroupPerm() {
            let endData = JSON.parse(JSON.stringify(this.selectedGroupPerm))
            if (endData.id) {
                endData.line_id = this.selectedGroupPerm.id
                delete endData.id
            }
            await this.$request.send('manage-tech.edit-add-group-perm', endData)
            this.getGroupPerms()
            if (!this.selectedGroupPerm?.id) {
                this.selectedGroupPerm = {}
            }
            this.$toast.success('Groupe modifié')
        },
        
        async deleteGroupPerm() {
            let response = await this.$request.send('manage-tech.delete-group-perm', {
                line_id: this.selectedGroupPerm?.id
            })
            if (response == 1) {
                this.$toast.success('Groupe supprimé')
                this.selectedGroupPerm = {}
                this.getGroupPerms()
            }
        },
    },
    mounted() {
        this.getGroupPerms()
    }
}
</script>